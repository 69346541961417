import { Injectable } from "@angular/core";
import { KeyValueModel } from "../models/keyValue-model";
import { IdNameModel } from "../models/idName-model";

@Injectable()
export class ConstantsService {
  apiUrl: string;
  emailRegex = "^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$";

  constructor() {
    this.initApiUrl();
  }

  get version(): string {
    return "3.1.0";
  }

  get dataVersion(): string {
    return "1.0.2";
  }

  get sportsIsBeta(): boolean {
    return false;
  }

  getBooleanEnum(): object[] {
    return [
      {
        key: true,
        value: "Da",
      },
      {
        key: false,
        value: "Nu",
      },
    ];
  }

  getHoursEnum(): KeyValueModel[] {
    const result: any[] = [];

    for (let i = 0; i <= 24; i++) {
      result.push({
        key: i * 2,
        value: i.toString().padStart(2, "0") + ":00",
      });
      result.push({
        key: i * 2 + 1,
        value: i.toString().padStart(2, "0") + ":30",
      });
    }
    result.splice(result.length - 1, 1);

    return result;
  }

  getHoursStepsWith(startHHmm: string, endHHmm: string, durationH: number): IdNameModel[] {
    const crt = ConstantsService.dateFromHour(startHHmm);

    const end = ConstantsService.dateFromHour(endHHmm);

    const tmpHours = [];
    while (crt < end) {
      const value = `${crt.getHours().toString().padStart(2, "0")}:${crt
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;
      tmpHours.push({
        id: crt.getHours() + crt.getMinutes() / 60,
        name: value,
      });

      crt.setMinutes(crt.getMinutes() + durationH * 60);
    }
    return tmpHours;
  }

  recursiveReservationPeriods(): IdNameModel[] {
    return [
      {
        id: 1,
        name: "O zi",
      },
      {
        id: 7,
        name: "O săptămână",
      },
      {
        id: 14,
        name: "Două săptămâni",
      },
    ];
  }

  private static dateFromHour(HHmm: string) {
    const date = new Date();
    date.setHours(parseInt(HHmm.split(":")[0], 10), parseInt(HHmm.split(":")[1], 10));
    return date;
  }

  private initApiUrl(): void {
    const isSSR = typeof window === "undefined";

    if (isSSR) {
      /// TODO: fix this shit
      this.apiUrl = "http://localhost:56315/api/";
      return;
    }

    this.apiUrl = "/api/";
  }
}
