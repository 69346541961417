import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable()
export class ErrorHandlerService {
  constructor(private snackBar: MatSnackBar) {}

  handleError(error: any) {
    if (!error.status) {
      this.snackBar.open("Nu s-a putut stabili conexiunea cu serverul", "Ok", {
        duration: 10000,
      });
    } else {
      if (error.status === 401) {
        this.snackBar.open("Nu aveți acces la aceasta funcționalitate", "Ok", {
          duration: 10000,
        });
      } else if (error.status === 500) {
        this.snackBar.open("A apărut o eroare neașteptată", "Ok", {
          duration: 10000,
        });
      } else {
        const body = error._body || error.error;
        if (body) {
          this.snackBar.open(body, "Ok", {
            duration: 10000,
          });
        }
      }
    }
  }

  handleHttpClientError(error: any): string {
    let errorMessage;
    if (!error.status) {
      errorMessage = "Nu există conexiune la internet";
    } else if (error.error != null) {
      if (error.status === 401) {
        errorMessage = "Nu aveți acces la aceasta funcționalitate!";
      } else if (error.status === 500) {
        errorMessage = "A apărut o eroare neașteptată!";
      } else {
        const errorBody = error.error;
        if (errorBody && typeof errorBody === "string" && errorBody.indexOf("[") === 0) {
          const errors = JSON.parse(errorBody);
          const errorDescriptions: string[] = [];
          errors.forEach((err) => errorDescriptions.push(err.description));
          errorMessage = errorDescriptions.join("<br>");
        } else {
          errorMessage =
            typeof errorBody === "string" ? errorBody : "A apărut o eroare neașteptată!";
        }
      }
    } else {
      errorMessage = "A apărut o eroare neașteptată!";
    }
    this.snackBar.open(errorMessage.replace(new RegExp("<br>", "g"), ","), "Ok", {
      duration: 20 * 1000,
    });
    return errorMessage;
  }

  showBasicMessage(error: string, time?: number): void {
    this.snackBar.open(error, "Ok", {
      duration: time || 10000,
    });
  }
}
