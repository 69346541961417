import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NavigationEnd, Router } from "@angular/router";
import { GoogleAnalyticsService } from "@shared/services/google-analytics.service";

@Component({
  selector: "booking-wash-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "wash";
  year = new Date().getFullYear();

  constructor(
    private readonly _dialog: MatDialog,
    private readonly _router: Router,
    private readonly _snackBar: MatSnackBar,
    public gaService: GoogleAnalyticsService
  ) {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this._snackBar.dismiss();
        this.gaService.sendViewPageEvent(event.urlAfterRedirects);
      }
    });
  }
}
