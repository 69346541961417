import { Injectable } from "@angular/core";
import { Observable, Observer, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { map, tap } from "rxjs/internal/operators";
import { InMemoryCache } from "./in-memory-cache";
import { AuthService } from "../auth.service";

@Injectable({
  providedIn: "root",
})
export class GetRequestCachingHttpClient {
  constructor(
    private cache: InMemoryCache,
    private http: HttpClient,
    private authService: AuthService
  ) {
    // TODO: I think this is usefull in some cases
    // this.authService.authStatusChanged.subscribe(status => {
    //   if (!status.isLoggedIn) {
    //     this.observableCache.clear();
    //     this.cache.clearAll();
    //   }
    // });
  }

  private observableCache = new Map<string, Observable<any>>();

  private static getCacheKey(url): string {
    if (url.startsWith("http")) {
      url = "/" + url.split("/").slice(3).join("/");
    }
    return url;
  }

  get<T>(url: string, options?: any): Observable<T> {
    const cacheKey = GetRequestCachingHttpClient.getCacheKey(url);
    const cachedResponse = this.cache.get(cacheKey);
    if (cachedResponse) {
      return of(cachedResponse);
    } else {
      return this.makeRequestObservable<T>(url, cacheKey, options);
    }
  }

  private makeRequestObservable<T>(url: string, cacheKey: string, options?: any): Observable<T> {
    if (this.observableCache.has(cacheKey)) {
      return this.observableCache.get(cacheKey);
    }

    const observers: Observer<any>[] = [];
    const observable = new Observable((observer) => {
      observers.push(observer);
    });

    const requestObs = this.http
      .get<T>(url, options)
      .pipe(
        map((response: any) => {
          return <T>response;
        })
      )
      .pipe(
        tap((data) => {
          this.cache.put(cacheKey, data);
          for (const observer of observers) {
            observer.next(data);
            observer.complete();
          }
          this.observableCache.delete(cacheKey);
        })
      );

    this.observableCache.set(cacheKey, observable);
    return requestObs;
  }

  clearCache() {
    this.cache.clearAll();
  }
}
