import { Injectable } from "@angular/core";
import { ConstantsService } from "./constants.service";
import { Observable } from "rxjs";
import { RoomModel } from "../models/room-model";
import { AuthService } from "./auth.service";
import { HttpClient } from "@angular/common/http";
import { GetRequestCachingHttpClient } from "./caching/get-request-caching-http-client";

@Injectable()
export class RoomService {
  constructor(
    private readonly _http: HttpClient,
    private readonly _constants: ConstantsService,
    private readonly _auth: AuthService,
    private readonly _grchc: GetRequestCachingHttpClient
  ) {}

  getAllWithSubscribe(id: number, cache?: boolean): Observable<RoomModel[]> {
    const url = this._constants.apiUrl + "Room/GetAllByDorm/" + id?.toString();
    if (!cache) {
      return <Observable<RoomModel[]>>this._http.get(url, this._auth.getOptions2(true));
    } else {
      return this._grchc.get(url, this._auth.getOptions2(true));
    }
  }
}
