import { Component, OnInit } from "@angular/core";
import { StudentService } from "../../services/student.service";
import { StudentModel } from "../../models/student-model";

@Component({
  selector: "booking-profile-page",
  templateUrl: "./profile-page.component.html",
  styleUrls: ["./profile-page.component.scss"],
})
export class ProfilePageComponent implements OnInit {
  studentInfo: StudentModel = null;

  constructor(private studentService: StudentService) {}

  ngOnInit() {
    this.getStudentInfo();
  }

  getStudentInfo() {
    this.studentService.getStudentInfo().subscribe((res) => {
      this.studentInfo = res;
    });
  }
}
