import { Injectable } from "@angular/core";
import { Observable, Observer } from "rxjs";

@Injectable()
export class SideMenuService {
  stateChanged: Observable<boolean>;
  private _observer: Observer<boolean>;
  private _hidden = true;

  constructor() {
    this.stateChanged = new Observable((observer: Observer<any>) => {
      this._observer = observer;
    });
  }

  hide(): void {
    this._hidden = true;
    this._observer.next(false);
  }

  show(): void {
    this._hidden = false;
    this._observer.next(true);
  }

  toggle(): void {
    this._hidden = !this._hidden;
    this._observer.next(!this._hidden);
  }
}
