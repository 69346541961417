import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "booking-new-version-disclaiemr",
  templateUrl: "./new-version-disclaimer.component.html",
  styleUrls: ["./new-version-disclaimer.component.scss"],
})
export class NewVersionDisclaimerComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<NewVersionDisclaimerComponent>) {}

  ngOnInit() {}

  close(): void {
    this.dialogRef.close();
  }
}
