import { Injectable } from "@angular/core";
import { ConstantsService } from "./constants.service";
import { AuthService } from "./auth.service";
import { Observable } from "rxjs";
import { DashboardModel } from "../models/dashboard-model";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class AdminDashboardService {
  constructor(
    private readonly _http: HttpClient,
    private readonly _constants: ConstantsService,
    private readonly _auth: AuthService
  ) {}

  getDashboard(): Observable<DashboardModel> {
    const url = this._constants.apiUrl + "Dashboard/GetDashboardForAdmin";
    return <Observable<DashboardModel>>this._http.get(url, this._auth.getOptions2(true));
  }

  resetPassword(): Observable<string> {
    const url = this._constants.apiUrl + "Account/ForgotPasswordForAdmin";
    return <Observable<string>>this._http.post(url, null, this._auth.getOptions2(true));
  }

  importStudents(text): Observable<string> {
    const url = this._constants.apiUrl + "Dashboard/ImportStudents";
    return <Observable<string>>this._http.post(url, text, this._auth.getOptions2(true));
  }
}
