import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class MenuItemsService {
  constructor(private readonly _auth: AuthService) {}

  getMenuItems() {
    const menuItems = [];
    if (
      this._auth.isLoggedIn() &&
      this._auth.isAdmin === false &&
      this._auth.isSportModerator === false
    ) {
      if (this._auth.hasDormRole) {
        menuItems.push({
          name: "Aplicații",
          route: "/apps",
        });
      } else {
        menuItems.push({
          name: "Baze sportive",
          route: "/sport",
        });
      }
      menuItems.push({
        name: "Istoric",
        route: "/history",
      });
    }
    if (this._auth.isModerator) {
      if (this._auth.hasDormRole) {
        menuItems.push({
          name: "Moderator",
          route: "/wash/moderator/home",
        });
        menuItems.push({
          name: "Tickets",
          route: "/wash/moderator/tickets",
        });
      }
    }
    if (this._auth.isAdmin) {
      menuItems.push({
        name: "Spălătorii",
        route: "/admin/dashboard/wash",
      });
      menuItems.push({
        name: "Baze sportive",
        route: "/admin/dashboard/sport",
      });
      menuItems.push({
        name: "Tickets",
        route: "/admin/tickets",
      });
    }

    menuItems.push({
      name: "Contact",
      route: "/contact",
    });

    return menuItems;
  }
}
