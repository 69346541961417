import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "@shared/services/auth.service";
import { MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ConstantsService } from "@shared/services/constants.service";
import { ErrorHandlerService } from "@shared/services/error-handler.service";

@Component({
  selector: "booking-wash-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent {
  loginForm: FormGroup;
  status: string = null;

  constructor(
    private readonly _formBuilder: FormBuilder,
    private readonly _authService: AuthService,
    private readonly _dialogRef: MatDialogRef<ForgotPasswordComponent>,
    private readonly _snackBar: MatSnackBar,
    private readonly _constants: ConstantsService,
    private readonly _errorHandlerService: ErrorHandlerService
  ) {
    this.loginForm = this._formBuilder.group({
      email: ["", [Validators.required, Validators.pattern(this._constants.emailRegex)]],
    });
  }

  formSubmit(): void {
    if (!this.loginForm.valid) {
      return;
    }
    this.status = "loading";
    this._authService.forgotPassword(this.loginForm.value).subscribe(
      (response) => {
        this.status = "success";
        this._snackBar.open(response, "Ok", {
          duration: 10000,
        });
        this._dialogRef.close(true);
      },
      (error) => {
        this._errorHandlerService.handleHttpClientError(error);
        this.status = null;
      }
    );
  }
}
