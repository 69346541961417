import { Component, OnInit } from "@angular/core";
import { ReservationModel } from "../../models/reservation-model";
import { WashReservationService } from "../../services/wash-reservation.service";
import { ErrorHandlerService } from "../../services/error-handler.service";
import { SportReservationService } from "../../services/sport/sport-reservation.service";
import { SportReservationModel } from "../../models/sport/sport-reservation.model";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: "booking-app-history",
  templateUrl: "./history-page.component.html",
  styleUrls: ["./history-page.component.scss"],
})
export class HistoryPageComponent implements OnInit {
  washReservations: ReservationModel[];
  sportReservations: SportReservationModel[];
  loadingWash: boolean;
  loadingSport = true;
  selectedTabIndex = 0;

  constructor(
    private readonly _reservationService: WashReservationService,
    private readonly _sportReservationService: SportReservationService,
    private readonly _errorHandler: ErrorHandlerService,
    private readonly _auth: AuthService
  ) {}

  get somethingReady(): boolean {
    return !this.loadingSport || (this._auth.hasDormRole && !this.loadingWash);
  }

  ngOnInit(): void {
    this.washReservations = null;
    this.sportReservations = null;
    if (this._auth.hasDormRole) {
      this.getWashHistory();
    }
    this.getSportHistory();
  }

  getWashHistory(): void {
    this.loadingWash = true;
    this._reservationService.getHistory().subscribe(
      (response) => {
        this.washReservations = response;
        // console.log(this.washReservations);
        this.loadingWash = false;
      },
      (error) => {
        this.loadingWash = false;
        this._errorHandler.handleError(error);
      }
    );
  }

  getSportHistory(): void {
    this.loadingSport = true;
    this._sportReservationService.getHistory().subscribe(
      (response) => {
        this.sportReservations = response;
        // console.log(this.sportReservations);
        this.loadingSport = false;
      },
      (error) => {
        this.loadingSport = false;
        this._errorHandler.handleError(error);
      }
    );
  }

  formatDate(date: string): string {
    return date.replace("T", " ");
  }

  get hasDormRole(): boolean {
    return this._auth.hasDormRole;
  }
}
