<div class="mat-elevation-z6 padding-16 profile-container">
  <div class="col-md-12 margin-bottom-8 mat-title"><b>Profil</b></div>

  <div class="col-md-12 padding-topBottom-8 mat-body-1" *ngIf="studentInfo">
    <div class="mat-subheading-2">
      <b>{{ studentInfo.name }}</b>
    </div>
    <div>
      <hr />
      CNP: <span class="mat-body-2">{{ studentInfo.cnp }}</span>
    </div>
    <div *ngIf="studentInfo.faculty">
      <hr />
      Student la: <span class="mat-body-2">{{ studentInfo.faculty.name }}</span>
    </div>
    <div *ngIf="studentInfo.dorm">
      <hr />
      Cazat în căminul:
      <span class="mat-body-2">{{ studentInfo.dorm.name }}</span
      >, camera: <span class="mat-body-2">{{ studentInfo.room.name }}</span>
    </div>
    <hr />
  </div>
</div>
