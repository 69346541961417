import { EventEmitter, Injectable } from "@angular/core";
import { ConstantsService } from "./constants.service";
import { AuthInfo, AuthStatus } from "../models/auth-status";
import { Observable } from "rxjs";

import { LoginModel, LoginResponseModel } from "../models/login-model";
import { RegisterModel } from "../models/register-model";
import { Router } from "@angular/router";
import { LocalStorageTdr } from "./web-storage-tdr/local-storage-tdr.service";
import { map } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ResetPasswordModel } from "@shared/models/reset-password.model";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  authStatusChanged = new EventEmitter<{ isLoggedIn: boolean }>();

  private _authHeaders: HttpHeaders;
  private readonly _noAuthHeaders: HttpHeaders;
  private _authInfo: AuthInfo = null;
  private _authToken: string = null;

  constructor(
    private readonly _http: HttpClient,
    private readonly _constants: ConstantsService,
    private readonly _localStorage: LocalStorageTdr,
    private readonly _router: Router
  ) {
    this._loadLocal();

    this._noAuthHeaders = this._makeHeadersObjects();
    this._authHeaders = this._makeHeadersObjects(true);
  }

  isAdmin: boolean;
  isModerator: boolean;
  isSportModerator: boolean;
  hasDormRole: boolean;

  login(model: LoginModel): Observable<LoginResponseModel> {
    const url = this._constants.apiUrl + "Account/Login";
    return this._http.post(url, model).pipe(
      map((data: LoginResponseModel) => {
        this._doLogin(data);
        return data;
      })
    );
  }

  forgotPassword(model: { email: string }): Observable<any> {
    const url = this._constants.apiUrl + "Account/ForgotPassword";
    return this._http.post(url, model, { responseType: "text" });
  }

  changePassword(model: ResetPasswordModel): Observable<any> {
    const url = this._constants.apiUrl + "Account/ResetPassword";
    return this._http.post(url, model, { responseType: "text" });
  }

  isLoggedIn(): boolean {
    return this._authToken !== null;
  }

  getOptions2(needsAuth?: boolean): { headers: HttpHeaders } {
    const headers = needsAuth ? this._authHeaders : this._noAuthHeaders;
    return { headers };
  }

  getAuthInfo(): AuthInfo {
    if (!this.isLoggedIn()) {
      return null;
    }
    return this._authInfo;
  }

  hasRole(role: string): boolean {
    if (!this.isLoggedIn()) {
      return false;
    }
    return this.getAuthInfo().roles.indexOf(role) !== -1;
  }

  logout(): void {
    this._authHeaders = null;
    this._localStorage.clear("_authInfo");
    this._localStorage.clear("_authToken");
    this._localStorage.clear("dataVersion");
    this._loadLocal();
    const status = { isLoggedIn: this.isLoggedIn() };
    this.authStatusChanged.next(status);
    void this._router.navigate(["/landing-page"]).then();
  }

  getStatus(callback: (status: AuthStatus) => void): void {
    const status = { isLoggedIn: this.isLoggedIn() };
    callback(status);
  }

  register(model: RegisterModel): Observable<any> {
    const url = `${this._constants.apiUrl}Account/Register`;
    return this._http.post(url, model, this.getOptions2(false));
  }

  private _doLogin(authInfo: LoginResponseModel) {
    const auth_info: AuthInfo = {
      token: authInfo.auth_token,
      roles: authInfo.roles?.length ? (authInfo.roles as string).split(",") : [],
      id: parseInt(authInfo.id),
      email: authInfo.email,
      name: authInfo.name,
      expires_in: authInfo.expires_in,
    };

    this._localStorage.set("_authInfo", auth_info);
    this._localStorage.set("_authToken", auth_info.token);
    this._localStorage.set("dataVersion", this._constants.dataVersion);
    this._loadLocal();

    this.authStatusChanged.next({ isLoggedIn: true });
  }

  private _makeHeadersObjects(putAuthToken?: boolean): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    headers = headers.append("Accept", "application/json");

    if (putAuthToken && this._localStorage.get("_authToken")) {
      const authToken = this._localStorage.get("_authToken") as string;
      headers = headers.delete("Authorization");
      headers = headers.set("Authorization", "Bearer " + authToken);
    }

    return headers;
  }

  private _loadLocal(): void {
    const currentDataVersion = this._localStorage.get("dataVersion") as string;

    if (currentDataVersion !== this._constants.dataVersion) {
      this._localStorage.clear("_authInfo");
      this._localStorage.clear("_authToken");
      this._localStorage.clear("dataVersion");
    }

    this._authInfo = this._localStorage.get("_authInfo") as AuthInfo;
    this._authToken = this._localStorage.get("_authToken") as string;

    this.isAdmin = this._authInfo && this._authInfo.roles.indexOf("Admin") >= 0;
    this.isModerator = this._authInfo && this._authInfo.roles.indexOf("Moderator") >= 0;
    this.isSportModerator = this._authInfo && this._authInfo.roles.indexOf("SportModerator") >= 0;
    this.hasDormRole = this._authInfo && this._authInfo.roles.indexOf("Dorm") >= 0;

    this._authHeaders = this._makeHeadersObjects(true);
  }
}
