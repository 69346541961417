import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { LoginComponent } from "../login/login.component";
import { AuthService } from "@shared/services/auth.service";
import { AuthStatus } from "@shared/models/auth-status";
import { Subscription } from "rxjs";
import { SideMenuService } from "@shared/services/side-menu.service";
import { MenuItemsService } from "@shared/services/menu-items.service";
import { Router } from "@angular/router";

@Component({
  selector: "booking-wash-header-menu",
  templateUrl: "./header-menu.component.html",
  styleUrls: ["./header-menu.component.scss"],
})
export class HeaderMenuComponent implements OnInit, OnDestroy {
  authName = "";
  isLoggedIn: boolean;
  menuItems: any[];
  menuDisabled: boolean;
  private readonly _authStatusSubscription: Subscription;

  constructor(
    private readonly _dialog: MatDialog,
    private readonly _sideMenuService: SideMenuService,
    private readonly _authService: AuthService,
    private readonly _router: Router,
    private readonly _menuItemsService: MenuItemsService
  ) {
    this.isLoggedIn = false;
    this._authService.getStatus((status) => this._authStatusChanged(status));
    this._authStatusSubscription = this._authService.authStatusChanged.subscribe((status) =>
      this._authStatusChanged(status)
    );
  }

  ngOnInit(): void {
    this._setMenuItems();
    if (typeof window !== "undefined") {
      this.onResize(null, window.innerWidth);
    }
  }

  toggleSideMenu(): void {
    this._sideMenuService.toggle();
  }

  doLogout(): void {
    this._authService.logout();
  }

  goToProfilePage(): void {
    void this._router.navigate(["/", "profile"]).then();
  }

  openLoginModal(): void {
    this._dialog.open(LoginComponent, { width: "400px" });
  }

  onResize(event, innerWidth): void {
    this._sideMenuService.hide();

    this.menuDisabled =
      (event && event.target.innerWidth < 768) || (innerWidth && innerWidth < 768);
  }

  ngOnDestroy(): void {
    if (this._authStatusSubscription) {
      this._authStatusSubscription.unsubscribe();
    }
  }

  private _authStatusChanged(status: AuthStatus): void {
    this._setMenuItems();
    if (status.isLoggedIn) {
      const authInfo = this._authService.getAuthInfo();
      this.authName = authInfo.name || "no name";
    }
    this.isLoggedIn = status.isLoggedIn;
  }

  private _setMenuItems(): void {
    this.menuItems = [];

    this.menuItems = this._menuItemsService.getMenuItems();
  }
}
