import { Injectable } from "@angular/core";

@Injectable()
export class LocalStorageTdr {
  lsObject: any;

  constructor() {
    this.lsObject = typeof localStorage === "undefined" ? new MockStorage() : localStorage;
  }

  set(key: string, value: any) {
    this.lsObject.setItem(key, JSON.stringify({ v: value }));
  }

  get(key: string): any {
    if (!this.lsObject.getItem(key)) {
      return null;
    }
    return JSON.parse(this.lsObject.getItem(key)).v;
  }

  clear(key: string): void {
    this.lsObject.clear(key);
  }
}
