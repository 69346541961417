import { Component, OnInit } from "@angular/core";
import { SideMenuService } from "@shared/services/side-menu.service";
import { AuthService } from "@shared/services/auth.service";
import { Subscription } from "rxjs";
import { AuthStatus } from "@shared/models/auth-status";
import { MatDialog } from "@angular/material/dialog";
import { LoginComponent } from "../login/login.component";
import { MenuItemsService } from "@shared/services/menu-items.service";
import { Router } from "@angular/router";

@Component({
  selector: "booking-wash-side-menu",
  templateUrl: "./side-menu.component.html",
  styleUrls: ["./side-menu.component.scss"],
})
export class SideMenuComponent implements OnInit {
  hidden = true;
  menuItems: any[] = [];
  authName = "";
  isLoggedIn: boolean;
  private _authStatusSubscription: Subscription;

  constructor(
    private readonly _authService: AuthService,
    private readonly _sideMenuService: SideMenuService,
    private readonly _dialog: MatDialog,
    private readonly _router: Router,
    private readonly _menuItemsService: MenuItemsService
  ) {
    this._sideMenuService.stateChanged.subscribe((value) => {
      this.hidden = !value;
    });

    this.isLoggedIn = false;

    this._authService.getStatus((status) => this._authStatusChanged(status));
    this._authStatusSubscription = this._authService.authStatusChanged.subscribe((status) =>
      this._authStatusChanged(status)
    );
  }

  ngOnInit(): void {
    this._sideMenuService.hide();
    this._setMenuItems();
  }

  doLogout(): void {
    this._authService.logout();
  }

  goToProfilePage(): void {
    void this._router.navigate(["/", "profile"]).then();
  }

  openLoginModal(): void {
    this._dialog.open(LoginComponent, { width: "400px" });
  }

  menuItemClicked(): void {
    this._sideMenuService.hide();
  }

  private _authStatusChanged(status: AuthStatus): void {
    this._setMenuItems();
    if (status.isLoggedIn) {
      const authInfo = this._authService.getAuthInfo();
      this.authName = authInfo.name || "no name";
    }
    this.isLoggedIn = status.isLoggedIn;
  }

  private _setMenuItems() {
    this.menuItems = [];
    this.menuItems = this._menuItemsService.getMenuItems();
  }
}
