import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "@shared/services/auth.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ConstantsService } from "@shared/services/constants.service";
import { ErrorHandlerService } from "@shared/services/error-handler.service";
import { TicketsService } from "@shared/services/tickets.service";
import { StudentService } from "@shared/services/student.service";
import { StudentModel } from "@shared/models/student-model";
import { TicketModel } from "@shared/models/ticket-model";
import { FacultyService } from "@shared/services/faculty.service";
import { FacultyModel } from "@shared/models/faculty-model";
import { DormModel } from "@shared/models/dorm-model";
import { DormService } from "@shared/services/dorm.service";
import { take } from "rxjs/operators";
import { RoomService } from "@shared/services/room.service";
import { RoomModel } from "@shared/models/room-model";
import { Router } from "@angular/router";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { ForgotPasswordComponent } from "../../auth/forgot-password/forgot-password.component";

@Component({
  selector: "booking-ticket-form",
  templateUrl: "./ticket-form.component.html",
})
export class TicketFormComponent implements OnInit {
  ticketForm: FormGroup;
  student: StudentModel = null;
  faculties: FacultyModel[] = [];
  dorms: DormModel[] = [];
  newDormRooms: RoomModel[] = [];
  currentDormRooms: RoomModel[] = [];
  status: string = null;
  isIdiot = "not yet";
  type;
  problemDomains: { value: string; placeholder: string }[] = [];
  typeOptions: { value: string; placeholder: string }[] = [];
  readonly isLoggedIn;

  private _problemTypes = {
    laundry: [
      {
        placeholder: `Camera mea din aplicație e greșită / Wrong room`,
        value: `room`,
      },
      {
        placeholder: `Căminul meu din aplicație e greșit / Wrong dorm`,
        value: `dorm`,
      },
    ],
    register: [
      {
        placeholder: `Email-ul este deja folosit / Email already used`,
        value: `email`,
      },
      {
        placeholder: `Acest CNP nu există / This Personal Identity Number doesn't exist`,
        value: `cnp`,
      },
    ],
    auth: [
      {
        placeholder: "Email-ul sau parola sunt greşite / Wrong email or password",
        value: "auth",
      },
    ],
    sportSpaces: [],
    other: [],
  };

  constructor(
    private readonly _formBuilder: FormBuilder,
    private readonly _ticketsService: TicketsService,
    private readonly _studentService: StudentService,
    private readonly _authService: AuthService,
    private readonly _facultyService: FacultyService,
    private readonly _dormsService: DormService,
    private readonly _roomsService: RoomService,
    private readonly _snackBar: MatSnackBar,
    private readonly _constants: ConstantsService,
    private readonly _errorHandler: ErrorHandlerService,
    private readonly _router: Router,
    private readonly _dialog: MatDialog
  ) {
    this.isLoggedIn = this._authService.isLoggedIn();
    this._groupContactFormControls();
    this._seedModels();
    this._checkIfMayBeDormless();
  }

  ngOnInit(): void {
    this._setProblemDomains();

    if (this.isLoggedIn) {
      this._getStudentInfo();
    }

    this._handleTypeChange();
    this._handleDomainChange();
  }

  formSubmit(): void {
    if (!this._isValid) {
      return;
    }
    this.status = "loading";
    this._ticketsService
      .createTicket(this._ticket)
      .pipe(take(1))
      .subscribe(
        () => this._handleSuccessResponse(),
        (error) => this._handleErrorResponse(error)
      );
  }

  private get _isValid(): boolean {
    if (!this.ticketForm.valid) {
      this._snackBar.open("Te rog sa completezi formularul inainte de a trimite", "ok", {
        duration: 10000,
      });
      return false;
    }

    return this.status !== "loading";
  }

  private get _ticket(): TicketModel {
    return {
      name: this.ticketForm.value.name,
      cnp: this.ticketForm.value.cnp,
      studentId: this.student ? this.student.id : null,
      email: this.ticketForm.value.email,
      type: this.ticketForm.value.type,
      domain: this.ticketForm.value.domain,
      currentDormId: this.student ? this.student.dormId : this.ticketForm.value.currentDormId,
      currentRoomId: this.student ? this.student.roomId : this.ticketForm.value.currentRoomId,
      newDormId: this.ticketForm.value.newDormId,
      newRoomId: this.ticketForm.value.newRoomId,
      facultyId: this.ticketForm.value.facultyId,
      description: this.ticketForm.value.description,
      isResolvedEmailSent: false,
    };
  }

  private _handleSuccessResponse() {
    this.status = "success";

    const dialogRef = this._dialog.open(ResponseDialogComponent, {
      disableClose: true,
      width: "400px",
      hasBackdrop: false,
      data:
        "Problema ta a fost înregistrată. Vei primi pe email o copie a datelor completate. " +
        "Vom încerca să rezolvăm problema în cel mai scurt timp posibil și " +
        "vei primi un email de confirmare.",
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this._router.navigateByUrl("/").then();
      });
  }

  private _handleErrorResponse(error) {
    this.status = "error";
    this._errorHandler.handleHttpClientError(error);
  }

  private _groupContactFormControls(): void {
    this.ticketForm = this._formBuilder.group({
      name: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.pattern(this._constants.emailRegex)]],
      cnp: ["", [Validators.required]],
      domain: ["", [Validators.required]],
      type: [""],
      description: [""],
    });
  }

  private _seedModels() {
    this._facultyService
      .getAllWithSubscribe()
      .pipe(take(1))
      .subscribe((faculties: FacultyModel[]) => {
        this.faculties = faculties;
      });

    this._dormsService
      .getAllWithSubscribe()
      .pipe(take(1))
      .subscribe((dorms: DormModel[]) => {
        this.dorms.push(...dorms);
      });
  }

  private _getStudentInfo() {
    this._studentService
      .getStudentInfo()
      .pipe(take(1))
      .subscribe((res) => {
        this.student = res;
        this._patchFormValues();
      });
  }

  private _patchFormValues() {
    this.ticketForm.controls["name"].setValue(this.student.name);
    this.ticketForm.controls["cnp"].setValue(this.student.cnp);
    this.ticketForm.controls["email"].setValue(this._authService.getAuthInfo().email);
    this._disablePatchedControls();
  }

  private _disablePatchedControls() {
    this.ticketForm.controls["name"].disable();
    this.ticketForm.controls["cnp"].disable();
    this.ticketForm.controls["email"].disable();

    if (this.ticketForm.controls["currentDormId"]) {
      this.ticketForm.controls["currentDormId"].disable();
    }

    if (this.ticketForm.controls["currentRoomId"]) {
      this.ticketForm.controls["currentRoomId"].disable();
    }
  }

  private _addAdditionalInfoControls() {
    this.ticketForm.addControl("facultyId", new FormControl("", [Validators.required]));
    this.ticketForm.addControl("currentRoomId", new FormControl(""));
    this.ticketForm.addControl("currentDormId", new FormControl(""));
    this._handleCurrentDormChange();
  }

  private _addNewRoomControls(dormId) {
    this.ticketForm.addControl("newRoomId", new FormControl("", [Validators.required]));
    this._roomsService
      .getAllWithSubscribe(dormId)
      .pipe(take(1))
      .subscribe((rooms: RoomModel[]) => {
        this.newDormRooms = [];
        this.newDormRooms.push(...rooms);
        this.newDormRooms.splice(
          this.newDormRooms.indexOf(
            this.newDormRooms.find((room) => room.name === "ADMINISTRAŢIE"),
            1
          )
        );
      });
  }

  private _addCurrentRoomControls(dormId) {
    const roomId = this.student ? (this.student.room ? this.student.room.id : null) : null;
    this.ticketForm.addControl("currentRoomId", new FormControl(roomId, [Validators.required]));
    this._roomsService
      .getAllWithSubscribe(dormId)
      .pipe(take(1))
      .subscribe((rooms: RoomModel[]) => {
        this.currentDormRooms = [];
        this.currentDormRooms.push(...rooms);
      });
  }

  private _addDormsControls() {
    const dormId = this.student?.dorm?.id || null;

    this.ticketForm.addControl("currentDormId", new FormControl(dormId, [Validators.required]));
    this._addCurrentRoomControls(dormId);

    this.ticketForm.addControl("newDormId", new FormControl("", [Validators.required]));
    this.ticketForm.get("newDormId").valueChanges.subscribe((val) => {
      this._addNewRoomControls(val);
    });
  }

  private _removeDormsControls() {
    this.ticketForm.removeControl("currentDormId");
    this.ticketForm.removeControl("newDormId");
    this._removeRoomsControls();
  }

  private _removeRoomsControls() {
    this.ticketForm.removeControl("currentRoomId");
    this.ticketForm.removeControl("newRoomId");
  }

  private _removeAdditionalInfoControls() {
    this.ticketForm.removeControl("facultyId");
    this.ticketForm.removeControl("currentRoomId");
    this.ticketForm.removeControl("currentDormId");
  }

  private _handleCurrentDormChange() {
    this.ticketForm.get("currentDormId").valueChanges.subscribe((dormId) => {
      if (dormId === -1) {
        this.ticketForm.removeControl("currentRoomId");
      } else {
        this._addCurrentRoomControls(dormId);
      }
    });
  }

  private _setProblemDomains() {
    this.isLoggedIn ? this._setLoggedInProblemDomains() : this._setNotLoggedInProblemDomains();
    this.problemDomains.push({
      value: "other",
      placeholder: "Altul / Other",
    });
  }

  private _setLoggedInProblemDomains() {
    this.problemDomains.push({
      value: "laundry",
      placeholder: "Spălătorii / Laundries",
    });
  }

  private _setNotLoggedInProblemDomains() {
    this.problemDomains.push(
      {
        value: "register",
        placeholder: "Procesul de înregistrare / Register process",
      },
      {
        value: "auth",
        placeholder: "Procesul de autentificare / Authentication process",
      }
    );
  }

  private _checkIfMayBeDormless(): void {
    if (!this.isLoggedIn || this._authService.isAdmin || this._authService.isModerator) {
      this.dorms.push({
        id: -1,
        name: "Nu stau în cămin",
      });
    }
  }

  private _handleTypeChange() {
    this.ticketForm.get("type").valueChanges.subscribe((val) => {
      switch (this.ticketForm.value.type) {
        case "room":
          this._removeRoomsControls();
          break;
        case "dorm":
          this._removeDormsControls();
          break;
        case "cnp":
          this._removeAdditionalInfoControls();
          break;
        case "email":
        case "auth":
          this.isIdiot = "not yet";
          break;
      }

      switch (val) {
        case "room":
          this._addCurrentRoomControls(this.student.dormId);
          this._addNewRoomControls(this.student.dormId);
          this._disablePatchedControls();
          break;
        case "dorm":
          this._addDormsControls();
          this._disablePatchedControls();
          break;
        case "cnp":
          this._addAdditionalInfoControls();
          break;
        case "email":
          this.isIdiot = "email";
          break;
        case "auth":
          this.isIdiot = "auth";
          break;
      }
    });
  }

  private _handleDomainChange() {
    this.ticketForm.get("domain").valueChanges.subscribe((val) => {
      this.typeOptions = this._problemTypes[val];
      const descriptionController = this.ticketForm.get("description");
      const problemType = this.ticketForm.get("type");
      problemType.setValue("");

      if (val === "other") {
        descriptionController.setValidators([Validators.required]);
      } else {
        descriptionController.setValidators(null);
      }

      descriptionController.updateValueAndValidity();

      if (this.typeOptions.length) {
        problemType.setValidators([Validators.required]);
      } else {
        problemType.setValidators(null);
      }

      problemType.updateValueAndValidity();
    });
  }

  openResetPasswordModal(): void {
    setTimeout(() => {
      this._dialog.open(ForgotPasswordComponent, { width: "400px" });
    }, 500);
  }
}

@Component({
  template:
    "<mat-dialog-content>" +
    "<p>{{data}}</p>" +
    "</mat-dialog-content>" +
    '<mat-dialog-actions align="center">\n' +
    "  <button mat-button mat-dialog-close>Ok</button>" +
    "</mat-dialog-actions>",
})
export class ResponseDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: string) {}
}
