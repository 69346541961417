import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoginComponent } from "./components/auth/login/login.component";
import { ConstantsService } from "./services/constants.service";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RegisterStudentComponent } from "./components/auth/register-student/register-student.component";
import { HeaderMenuComponent } from "./components/auth/header-menu/header-menu.component";
import { DormService } from "./services/dorm.service";
import { RouterModule } from "@angular/router";
import { ConfirmComponent } from "./components/modals/confirm/confirm.component";
import { LaundryService } from "./services/laundry.service";
import { StudentService } from "./services/student.service";
import { FacultyService } from "./services/faculty.service";
import { RoomService } from "./services/room.service";
import { WashReservationService } from "./services/wash-reservation.service";
import { AuthGuard } from "./guards/auth.guard";
import { LoginService } from "./services/login.service";
import { ModeratorGuard } from "./guards/moderator.guard";
import { AdminGuard } from "./guards/admin.guard";
import { ErrorHandlerService } from "./services/error-handler.service";
import { AdminDashboardService } from "./services/admin-dashboard.service";
import { SideMenuComponent } from "./components/auth/side-menu/side-menu.component";
import { SideMenuService } from "./services/side-menu.service";
import { ForgotPasswordComponent } from "./components/auth/forgot-password/forgot-password.component";
import { ChangePasswordComponent } from "./components/auth/change-password/change-password.component";
import { LocalStorageTdr } from "./services/web-storage-tdr/local-storage-tdr.service";
import { ModeratorDashboardService } from "./services/moderator-dashboard.service";
import { TableComponent } from "./components/elements/table/table.component";
import { CdkTableModule } from "@angular/cdk/table";
import { DormModalComponent } from "./components/modals/dorm-modal/dorm-modal.component";
import { LaundryModalComponent } from "./components/modals/laundry-modal/laundry-modal.component";
import { ReservationModalComponent } from "./components/modals/reservation-modal/reservation-modal.component";
import { StudentModalComponent } from "./components/modals/student-modal/student-modal.component";
import { StudentImportComponent } from "./components/modals/student-import/student-import.component";
import { ReservationDeleteIntervalComponent } from "./components/modals/reservation-delete-interval/reservation-delete-interval.component";
import { ScheduleComponent } from "./components/elements/schedule/schedule.component";
import { AppSelectorPageComponent } from "./pages/app-selector-page/app-selector-page.component";
import { SportSpaceService } from "./services/sport/sport-space.service";
import { HttpClientModule } from "@angular/common/http";
import { FlexLayoutModule } from "@angular/flex-layout";
import { LandingPageComponent } from "./pages/landing-page/landing-page.component";
import { SmartAutocompleteComponent } from "./components/elements/smart-autocomplete/smart-autocomplete.component";
import { WashScheduleComponent } from "./components/elements/wash-schedule/wash-schedule.component";
import { SportScheduleComponent } from "./components/elements/sport-schedule/sport-schedule.component";
import { SportService } from "./services/sport/sport.service";
import { SportSpaceModalComponent } from "./components/modals/sport-space-modal/sport-space-modal.component";
import { SportFacultyLimitationsService } from "./services/sport/sport-faculty-limitations.service";

// eslint-disable-next-line max-len
import { SportFacultyLimitationsModalComponent } from "./components/modals/sport-faculty-limitations-modal/sport-faculty-limitations-modal.component";
import { NewVersionDisclaimerComponent } from "./components/modals/new-version-disclaimer/new-version-disclaimer.component";
import { StudentListPageComponent } from "./pages/student-list-page/student-list-page.component";
import { ProfilePageComponent } from "./pages/profile-page/profile-page.component";
import { CombinedStudentModalComponent } from "./components/modals/combined-student-modal/combined-student-modal.component";
import { SportReservationService } from "./services/sport/sport-reservation.service";
import { SportReservationModalComponent } from "./components/modals/sport-reservation-modal/sport-reservation-modal.component";
import { SportReservationDeleteIntervalModalComponent } from ".";
import { HistoryPageComponent } from "./pages/history-page/history-page.component";
import { SportModeratorGuard } from "./guards/sport-moderator.guard";
import { StudentImportTutorialComponent } from "./components/modals/student-import-tutorial/student-import-tutorial.component";
import {
  ResponseDialogComponent,
  TicketFormComponent,
} from "./components/elements/ticket-form/ticket-form.component";
import { TicketFormModalComponent } from "./components/modals/ticket-form-modal/ticket-form-modal.component";
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from '@environments/environment';

const matImportedModules = [
  MatMenuModule,
  MatDialogModule,
  MatButtonModule,
  MatInputModule,
  MatCardModule,
  MatSnackBarModule,
  MatToolbarModule,
  MatCheckboxModule,
  MatTooltipModule,
  MatButtonModule,
  MatSnackBarModule,
  MatCheckboxModule,
  MatProgressSpinnerModule,
  MatIconModule,
  MatTableModule,
  MatChipsModule,
  MatPaginatorModule,
  MatSelectModule,
  MatRadioModule,
  MatTabsModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSortModule,
  MatAutocompleteModule,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    CdkTableModule,
    FlexLayoutModule,
    RecaptchaV3Module,
    ...matImportedModules,
  ],
  declarations: [
    LandingPageComponent,
    LoginComponent,
    RegisterStudentComponent,
    HeaderMenuComponent,
    ConfirmComponent,
    SideMenuComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    TableComponent,
    DormModalComponent,
    LaundryModalComponent,
    ReservationModalComponent,
    StudentModalComponent,
    StudentImportComponent,
    ReservationDeleteIntervalComponent,
    ScheduleComponent,
    SmartAutocompleteComponent,
    AppSelectorPageComponent,
    WashScheduleComponent,
    SportScheduleComponent,
    SportSpaceModalComponent,
    SportFacultyLimitationsModalComponent,
    NewVersionDisclaimerComponent,
    StudentListPageComponent,
    ProfilePageComponent,
    CombinedStudentModalComponent,
    SportReservationModalComponent,
    SportReservationDeleteIntervalModalComponent,
    HistoryPageComponent,
    StudentImportTutorialComponent,
    TicketFormComponent,
    TicketFormModalComponent,
    ResponseDialogComponent,
  ],
  providers: [
    ConstantsService,
    DormService,
    LaundryService,
    StudentService,
    FacultyService,
    RoomService,
    WashReservationService,
    LoginService,
    ModeratorGuard,
    SportModeratorGuard,
    AdminGuard,
    AuthGuard,
    ErrorHandlerService,
    AdminDashboardService,
    SideMenuService,
    ModeratorDashboardService,
    LocalStorageTdr,
    SportService,
    SportSpaceService,
    SportFacultyLimitationsService,
    SportReservationService,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
  ],
  entryComponents: [
    LoginComponent,
    RegisterStudentComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    ConfirmComponent,
    DormModalComponent,
    LaundryModalComponent,
    ReservationModalComponent,
    StudentModalComponent,
    StudentImportComponent,
    ReservationDeleteIntervalComponent,
    SportSpaceModalComponent,
    SportFacultyLimitationsModalComponent,
    NewVersionDisclaimerComponent,
    CombinedStudentModalComponent,
    SportReservationModalComponent,
    SportReservationDeleteIntervalModalComponent,
    StudentImportTutorialComponent,
    TicketFormModalComponent,
    ResponseDialogComponent,
  ],
  exports: [
    ...matImportedModules,
    LoginComponent,
    RegisterStudentComponent,
    HeaderMenuComponent,
    ConfirmComponent,
    SideMenuComponent,
    TableComponent,
    SmartAutocompleteComponent,
    WashScheduleComponent,
    SportScheduleComponent,
    NewVersionDisclaimerComponent,
    StudentListPageComponent,
    HistoryPageComponent,
    TicketFormComponent,
  ],
})
export class SharedModule {}
