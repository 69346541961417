import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthService } from "@shared/services/auth.service";
import { RegisterStudentComponent } from "../register-student/register-student.component";
import { ConstantsService } from "@shared/services/constants.service";
import { Router } from "@angular/router";
import { ForgotPasswordComponent } from "../forgot-password/forgot-password.component";
import { ErrorHandlerService } from "@shared/services/error-handler.service";

@Component({
  selector: "booking-wash-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent {
  loginForm: FormGroup;

  minPasswordLength = 6;
  status: string = null;

  constructor(
    private readonly _formBuilder: FormBuilder,
    private readonly _authService: AuthService,
    private readonly _router: Router,
    private readonly _dialog: MatDialog,
    private readonly _dialogRef: MatDialogRef<LoginComponent>,
    private readonly _snackBar: MatSnackBar,
    private readonly _constants: ConstantsService,
    private readonly _errorHandler: ErrorHandlerService
  ) {
    this.loginForm = this._formBuilder.group({
      username: ["", [Validators.required, Validators.pattern(this._constants.emailRegex)]],
      password: ["", [Validators.required, Validators.pattern(`.{${this.minPasswordLength},}`)]],
    });
  }

  formSubmit(): void {
    if (!this.loginForm.valid) {
      return;
    }
    if (this.status === "loading") {
      return;
    }
    this.status = "loading";
    this._authService.login(this.loginForm.value).subscribe(
      () => {
        this.status = "success";
        this._snackBar.open("Te-ai logat cu succes!", "Ok", {
          duration: 10000,
        });
        this._dialogRef.close(true);
        if (this._authService.isLoggedIn() && this._authService.isAdmin) {
          void this._router.navigate(["/admin"]).then();
        } else if (this._authService.isLoggedIn() && this._authService.isSportModerator) {
          void this._router.navigate(["/sport/moderator/schedule-printer"]).then();
        } else if (this._authService.isLoggedIn() && this._authService.isModerator) {
          void this._router.navigate(["/apps"]).then();
        }
      },
      (error) => {
        this.status = "error";
        this._errorHandler.handleHttpClientError(error);
      }
    );
  }

  openRegisterModal(): void {
    this._dialogRef.close(false);
    setTimeout(() => {
      this._dialog.open(RegisterStudentComponent, { width: "400px" });
    }, 500);
  }

  openResetPasswordModal(): void {
    this._dialogRef.close(false);
    setTimeout(() => {
      this._dialog.open(ForgotPasswordComponent, { width: "400px" });
    }, 500);
  }
}
