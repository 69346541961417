import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable()
export class ModeratorGuard implements CanActivate {
  constructor(private auth: AuthService, private snackBar: MatSnackBar) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.auth.hasRole("Moderator") && !this.auth.hasRole("Admin")) {
      this.snackBar.open("Nu ai acces la această pagină!", "Ok", {
        duration: 5000,
      });
      return false;
    }
    return true;
  }
}
