import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { DormModel } from "../models/dorm-model";
import { ConstantsService } from "./constants.service";

import { AuthService } from "./auth.service";
import { ErrorHandlerService } from "./error-handler.service";
import { HttpClient } from "@angular/common/http";
import { GetRequestCachingHttpClient } from "./caching/get-request-caching-http-client";
import { map } from "rxjs/operators";

@Injectable()
export class DormService {
  items: BehaviorSubject<DormModel[]> = new BehaviorSubject<DormModel[]>([]);
  private _itemStore: DormModel[];

  constructor(
    private readonly _errorHandler: ErrorHandlerService,
    private readonly _http: HttpClient,
    private readonly _constants: ConstantsService,
    private readonly _auth: AuthService,
    private readonly _grchc: GetRequestCachingHttpClient
  ) {}

  fetchAll(): void {
    this._itemStore = [];
    this.items.next(this._itemStore);

    this.getAllWithSubscribe().subscribe(
      (data: DormModel[]) => {
        this._itemStore = data;
        this.items.next(this._itemStore);
      },
      (error) => this._errorHandler.handleError(error)
    );
  }

  getAllWithSubscribe(cache?: boolean): Observable<DormModel[]> {
    const url = this._constants.apiUrl + "Dorm/GetAll";
    let result: Observable<DormModel[]>;

    if (!cache) {
      result = <Observable<DormModel[]>>this._http.get(url, this._auth.getOptions2(true));
    } else {
      result = this._grchc.get(url, this._auth.getOptions2(true));
    }

    return result.pipe(
      map((data: DormModel[]) => {
        data.sort((dorm1, dorm2) => {
          const d1 = parseInt(dorm1.name.replace(/\D/g, ""), 10);
          const d2 = parseInt(dorm2.name.replace(/\D/g, ""), 10);
          return d1 > d2 ? 1 : d1 === d2 ? 0 : -1;
        });
        return data;
      })
    );
  }

  add(item: DormModel): Observable<any> {
    const url = this._constants.apiUrl + "Dorm/Add";
    return this._http.post(url, item, this._auth.getOptions2(true));
  }

  update(item: DormModel): Observable<any> {
    const url = this._constants.apiUrl + "Dorm/Update";
    return this._http.put(url, item, this._auth.getOptions2(true));
  }

  delete(item: DormModel): Observable<any> {
    const url = this._constants.apiUrl + "Dorm/Delete/" + item.id.toString();
    return this._http.delete(url, this._auth.getOptions2(true));
  }
}
