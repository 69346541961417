import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable, Observer } from "rxjs";
import { AuthService } from "../services/auth.service";
import { Injectable } from "@angular/core";
import { LoginService } from "../services/login.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private loginService: LoginService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.auth.isLoggedIn()) {
      return new Observable((observer: Observer<boolean>) => {
        this.loginService.loginDialog().subscribe((loginResult) => {
          observer.next(loginResult);
        });

        this.router.navigate(["/"]).then();
        setTimeout(() => {
          this.snackBar.open("Trebuie să fii autentificat pentru a accesa această pagină!", "Ok", {
            duration: 5000,
          });
        }, 250);
      });
    }
    return true;
  }
}
