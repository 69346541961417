import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(private auth: AuthService, private snackBar: MatSnackBar, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.auth.hasRole("Admin")) {
      this.router.navigate(["/"]).then();
      setTimeout(() => {
        this.snackBar.open("Nu ai dreptul să accesezi această pagină!", "Ok", {
          duration: 5000,
        });
      }, 250);
      return false;
    }
    return true;
  }
}
