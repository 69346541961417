<h1 mat-dialog-title class="mat-title">
  <b>Schimbare parolă </b>
</h1>

<form [formGroup]="registerForm" (submit)="formSubmit()">
  <div mat-dialog-content>
    <div formGroupName="passwords">
      <mat-form-field class="col-12">
        <input
          matInput
          placeholder="Noua parola"
          title="Noua parola"
          type="password"
          formControlName="password"
          autocomplete="new-password"
        />
        <mat-error *ngIf="passwordsFormGroup.controls['password'].hasError('required')">
          Trebuie să introduci o parolă
        </mat-error>
        <mat-error *ngIf="passwordsFormGroup.controls['password'].hasError('pattern')">
          Parola trebuie să aibă minim {{ minPasswordLength }} caractere.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-12">
        <input
          matInput
          placeholder="Confirmă parola"
          title="Confirmă parola"
          type="password"
          formControlName="repeatPassword"
          autocomplete="new-password"
        />
        <mat-error *ngIf="passwordsFormGroup.controls['repeatPassword'].hasError('required')">
          Trebuie să confirmi parola
        </mat-error>
        <mat-error *ngIf="passwordsFormGroup.controls['repeatPassword'].hasError('areEqual')">
          Parolele nu coincid
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions>
    <div class="col-12 margin-bottom-24 margin-top-8">
      <button mat-raised-button [color]="'primary'" class="col-12">
        TRIMITE
        <i class="fa fa-spinner fa-pulse" *ngIf="status === 'loading'"></i>
      </button>
    </div>
  </div>
</form>
