<mat-toolbar color="primary" class="fixed-top mat-elevation-z6 navbar" layout="row">
  <div class="container" (window:resize)="onResize($event, null)">
    <span [hidden]="!menuDisabled" hidden>
      <a (click)="toggleSideMenu()" class="side-menu-button"><i class="fa fa-bars"></i></a>
    </span>
    <span class="mat-subheading-2 page-name">
      <a [routerLink]="['/landing-page']"><strong>Booking</strong><span class="light">App</span></a>
    </span>
    <span [hidden]="menuDisabled" *ngFor="let item of menuItems" class="margin-top-2">
      <a [routerLink]="[item.route]" mat-button>{{ item.name }}</a>
    </span>
    <span class="spacer"></span>
    <span class="margin-top-2" [hidden]="menuDisabled">
      <mat-menu #loginMenu="matMenu" [overlapTrigger]="false">
        <button mat-menu-item (click)="goToProfilePage()">
          <span> Profil </span>
        </button>
        <button mat-menu-item (click)="doLogout()">
          <span> Deconectează-te </span>
        </button>
      </mat-menu>
      <a mat-button [matMenuTriggerFor]="loginMenu" *ngIf="isLoggedIn" class="float-right href">
        {{ authName }}
      </a>
      <a (click)="openLoginModal()" mat-button class="float-right" *ngIf="!isLoggedIn"
        >Conectează-te</a
      >
    </span>
  </div>
</mat-toolbar>
