import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SportModel } from "../../models/sport/sport.model";
import { ConstantsService } from "../constants.service";
import { GetRequestCachingHttpClient } from "../caching/get-request-caching-http-client";
import { AuthService } from "../auth.service";
import { map } from "rxjs/operators";

@Injectable()
export class SportService {
  constructor(
    private grchc: GetRequestCachingHttpClient,
    private constants: ConstantsService,
    private auth: AuthService
  ) {}

  getAllWithSubscribe(): Observable<SportModel[]> {
    const url = this.constants.apiUrl + "sport/GetAll";

    return this.grchc.get(url, this.auth.getOptions2(true)).pipe(
      map((response: SportModel[]) => {
        return response;
      })
    );
  }
}
