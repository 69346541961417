<p class="col-12 col-md-12 mat-body-2 text-danger" *ngIf="!isLoggedIn">
  Atenție! Trebuie să introduci o adresă de email la care ai acces pentru a primi un răspuns și
  pentru a putea comunica în continuare.
</p>
<p class="col-12 col-md-12 mat-body-2 text-danger fs-6" *ngIf="isLoggedIn">
  Dacă ai <strong>camera</strong> sau <strong>căminul</strong> greșit, te rugăm să selectezi
  domeniul <strong>Spălătorii</strong>, apoi una dintre opțiunile de la tipul problemei
</p>

<form [formGroup]="ticketForm" (submit)="formSubmit()" novalidate>
  <div class="row">
    <mat-form-field class="col-md-4">
      <label>
        <input
          matInput
          placeholder="Numele complet / Full name"
          formControlName="name"
          type="text"
        />
      </label>
      <mat-error
        *ngIf="
          ticketForm.controls['name'].hasError('required') && ticketForm.controls['name'].dirty
        "
      >
        Trebuie să îți introduci numele!
      </mat-error>
    </mat-form-field>

    <mat-form-field class="col-md-4">
      <label>
        <input
          matInput
          placeholder="Adresă de email validă / Valid email address"
          formControlName="email"
          type="email"
        />
      </label>
      <mat-error
        *ngIf="
          ticketForm.controls['email'].hasError('required') && ticketForm.controls['email'].dirty
        "
      >
        Trebuie să introduci o adresă de email!
      </mat-error>
      <mat-error
        *ngIf="
          ticketForm.controls['email'].hasError('pattern') && ticketForm.controls['email'].dirty
        "
      >
        Trebuie să introduci o adresă de email validă!
      </mat-error>
    </mat-form-field>

    <mat-form-field class="col-md-4">
      <label>
        <input
          matInput
          placeholder="CNP / Personal Identity Number"
          formControlName="cnp"
          type="text"
        />
      </label>
      <mat-error
        *ngIf="ticketForm.controls['cnp'].hasError('required') && ticketForm.controls['cnp'].dirty"
      >
        Trebuie să îți introduci CNP-ul!
      </mat-error>
    </mat-form-field>
  </div>

  <div class="row">
    <mat-form-field class="col-md-4">
      <mat-label>Domeniul problemei / Problem domain</mat-label>
      <mat-select formControlName="domain">
        <mat-option *ngFor="let option of problemDomains" [value]="option.value">
          {{ option.placeholder }}
        </mat-option>
      </mat-select>
      <mat-error
        *ngIf="
          ticketForm.controls['domain'].hasError('required') && ticketForm.controls['domain'].dirty
        "
      >
        Trebuie să specifici domeniul problemei!
      </mat-error>
    </mat-form-field>

    <mat-form-field class="col-md-8" *ngIf="typeOptions && typeOptions.length">
      <mat-label>Tipul problemei / Problem type</mat-label>
      <mat-select formControlName="type">
        <mat-option *ngFor="let option of typeOptions" [value]="option.value">
          {{ option.placeholder }}
        </mat-option>
      </mat-select>
      <mat-error
        *ngIf="
          ticketForm.controls['type'].hasError('required') && ticketForm.controls['type'].dirty
        "
      >
        Trebuie să specifici tipul problemei!
      </mat-error>
    </mat-form-field>
  </div>

  <div class="row">
    <div class="col-md-4 pr-md-2">
      <mat-form-field class="col-12 pr-md-0" *ngIf="this.ticketForm.get('currentDormId')">
        <mat-label>Cămin curent / Current dorm</mat-label>
        <mat-select formControlName="currentDormId">
          <mat-option *ngFor="let dorm of dorms" [value]="dorm.id">
            {{ dorm.name }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="
            ticketForm.controls['currentDormId'].hasError('required') &&
            ticketForm.controls['currentDormId'].dirty
          "
        >
          Trebuie să selectezi căminul curent!
        </mat-error>
      </mat-form-field>

      <mat-form-field class="col-12 pr-md-0" *ngIf="this.ticketForm.get('currentRoomId')">
        <mat-label>Camera curentă / Current room</mat-label>
        <mat-select formControlName="currentRoomId">
          <mat-option *ngFor="let room of currentDormRooms" [value]="room.id">
            {{ room.name }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="
            ticketForm.controls['currentRoomId'].hasError('required') &&
            ticketForm.controls['currentRoomId'].dirty
          "
        >
          Trebuie să selectezi camera curentă!
        </mat-error>
      </mat-form-field>
    </div>

    <div
      class="col-md-4 ml-md-1"
      *ngIf="ticketForm.get('newDormId') || ticketForm.get('newRoomId')"
    >
      <mat-form-field class="col-12 pl-md-0" *ngIf="this.ticketForm.get('newDormId')">
        <mat-label>Cămin nou / New dorm</mat-label>
        <mat-select formControlName="newDormId">
          <mat-option *ngFor="let dorm of dorms" [value]="dorm.id">
            {{ dorm.name }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="
            ticketForm.controls['newDormId'].hasError('required') &&
            ticketForm.controls['newDormId'].dirty
          "
        >
          Trebuie să selectezi noul cămin!
        </mat-error>
      </mat-form-field>

      <mat-form-field class="col-12 pl-md-0" *ngIf="this.ticketForm.get('newRoomId')">
        <mat-label>Camera nouă / New room</mat-label>
        <mat-select formControlName="newRoomId">
          <mat-option *ngFor="let room of newDormRooms" [value]="room.id">
            {{ room.name }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="
            ticketForm.controls['newRoomId'].hasError('required') &&
            ticketForm.controls['newRoomId'].dirty
          "
        >
          Trebuie să selectezi noua cameră!
        </mat-error>
      </mat-form-field>
    </div>

    <mat-form-field class="col-md-6 ml-md-1" *ngIf="this.ticketForm.get('facultyId')">
      <mat-label>Facultatea / Faculty</mat-label>
      <mat-select formControlName="facultyId">
        <mat-option *ngFor="let faculty of faculties" [value]="faculty.id">
          {{ faculty.name }}
        </mat-option>
      </mat-select>
      <mat-error
        *ngIf="
          ticketForm.controls['facultyId'].hasError('required') &&
          ticketForm.controls['facultyId'].dirty
        "
      >
        Trebuie să selectezi facultatea!
      </mat-error>
    </mat-form-field>
  </div>

  <div [ngSwitch]="isIdiot">
    <mat-form-field class="col-12" *ngSwitchCase="'not yet'">
      <label>
        <textarea
          matInput
          placeholder="Detalii suplimentare dacă e necesar / Other details if needed"
          formControlName="description"
          type="text"
          matTextareaAutosize
          matAutosizeMinRows="10"
        ></textarea>
      </label>
      <mat-hint>
        <p>Scrie aici orice detalii care crezi că ne vor ajuta să rezolvăm mai repede problema.</p>
      </mat-hint>
      <mat-error
        *ngIf="
          ticketForm.controls['description'].hasError('required') &&
          ticketForm.controls['description'].dirty
        "
      >
        Trebuie să descrii detaliat problema întâmpinată!
      </mat-error>
    </mat-form-field>

    <div *ngSwitchCase="'email'" class="d-flex py-5 justify-content-center text-center">
      <p class="col-md-6">
        Această problemă apare în cazul în care ai deja un cont în aplicaţie!<br />
        <button
          mat-raised-button
          class="my-2"
          [color]="'primary'"
          (click)="$event.preventDefault(); openResetPasswordModal()"
        >
          <strong>Resetează parola</strong>
        </button>
        şi încearcă să te loghezi din nou sau intră pe email şi caută mail-ul primit de la
        BookingApp când te-ai înregistrat pentru a-ţi confirma adresa.
      </p>
    </div>

    <div *ngSwitchCase="'auth'" class="d-flex py-5 justify-content-center text-center">
      <p class="col-md-6">
        Această problemă apare în cazul în care nu ţi-ai creat un cont în aplicaţie sau ai greşit
        parola!<br />
        <button
          mat-raised-button
          class="my-2"
          [color]="'primary'"
          (click)="$event.preventDefault(); openResetPasswordModal()"
        >
          <strong>Resetează parola</strong>
        </button>
        şi încearcă să te loghezi sau înregistrează-te.
      </p>
    </div>
  </div>

  <button
    mat-raised-button
    class="margin-top-16 margin-left-16"
    [color]="'primary'"
    type="submit"
    [disabled]="isIdiot !== 'not yet' || status === 'success'"
  >
    Trimite / Send
    <em class="fa fa-spinner fa-pulse" *ngIf="status === 'loading'"></em>
  </button>
</form>
