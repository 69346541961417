<h1 mat-dialog-title class="mat-title">Conectare</h1>

<form [formGroup]="loginForm" (submit)="formSubmit()">
  <div mat-dialog-content>
    <mat-form-field class="col-12">
      <input
        matInput
        placeholder="Adresă email"
        title="Adresă email"
        formControlName="username"
        type="email"
      />
      <mat-error *ngIf="loginForm.controls['username'].hasError('required')">
        Trebuie să introduci o adresă de email.
      </mat-error>
      <mat-error *ngIf="loginForm.controls['username'].hasError('pattern')">
        Trebuie să introduci o adresă de email validă.
      </mat-error>
    </mat-form-field>
    <mat-form-field class="col-12 mb-2">
      <input
        matInput
        placeholder="Password"
        title="Password"
        type="password"
        formControlName="password"
        autocomplete="current-password"
      />
      <mat-error *ngIf="loginForm.controls['password'].hasError('required')">
        Trebuie să introduci o parolă
      </mat-error>
      <mat-error *ngIf="loginForm.controls['password'].hasError('pattern')">
        Parola trebuie să aibă minim {{ minPasswordLength }} caractere.
      </mat-error>
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="text-center">
    <div class="offset-2 col-8">
      <button mat-raised-button [color]="'primary'" type="submit">
        CONECTEAZĂ-TE
        <i class="fa fa-spinner fa-pulse" *ngIf="status === 'loading'"></i>
      </button>
    </div>
    <div class="col-12 margin-topBottom-16">
      <div>
        <span class="mat-body-1 text-gray">Nu ai încă un cont?</span>
        <a
          class="mat-body-1 btn-link padding-0"
          (click)="$event.preventDefault(); openRegisterModal()"
        >
          <strong>&nbsp;Creează cont nou (Register)</strong>
        </a>
      </div>
      <div>
        <span class="mat-body-1 text-gray">Ai uitat parola?</span>
        <a
          class="mat-body-1 btn-link padding-0"
          (click)="$event.preventDefault(); openResetPasswordModal()"
        >
          <strong>&nbsp;Resetează parola (Forgot password)</strong>
        </a>
      </div>
    </div>
  </div>
</form>
