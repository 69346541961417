<booking-wash-header-menu></booking-wash-header-menu>

<div class="site-container container pt-1 pt-sm-2 pt-md-3 pt-lg-4 pt-xl-5">
  <router-outlet></router-outlet>
  <!-- <div class="col-md-12 footer">
    &copy; {{year}} COSPol
    <br>
    Convenția Organizațiilor Studențești din Politehnică
    <br>
    Universitatea Politehnica Timişoara
  </div> -->
</div>

<booking-wash-side-menu></booking-wash-side-menu>
