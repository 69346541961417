import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ChangePasswordComponent } from "../../components/auth/change-password/change-password.component";
import { NewVersionDisclaimerComponent } from "../../components/modals/new-version-disclaimer/new-version-disclaimer.component";
import { ConstantsService } from "../../services/constants.service";

@Component({
  selector: "booking-landing-page",
  templateUrl: "./landing-page.component.html",
  styleUrls: ["./landing-page.component.scss"],
})
export class LandingPageComponent implements OnInit {
  fromOldWebsite: boolean;

  constructor(
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    public constants: ConstantsService
  ) {
    this.redirectIfLoggedIn();
  }

  ngOnInit() {}

  private redirectIfLoggedIn() {
    this.route.params.subscribe((params) => {
      const isConfirmed = params["isConfirmed"];
      if (isConfirmed != null && isConfirmed) {
        setTimeout(() => {
          this.snackBar.open(
            "Adresa de email a fost confirmată cu succes! Acum te poți conecta",
            "Ok",
            {
              duration: 10000,
            }
          );
        }, 100);
      } else {
        const userId = params["userId"];
        const code = params["code"];
        if (
          userId != null &&
          userId !== "" &&
          code != null &&
          code !== "" &&
          userId.length === 36 &&
          code.length > 100
        ) {
          setTimeout(() => {
            this.dialog.open(ChangePasswordComponent, {
              data: {
                userId: userId,
                code: code,
              },
              width: "400px",
            });
          }, 250);
        }
      }
    });
    this.route.queryParams.subscribe((queryParams) => {
      if (queryParams["ref"] === "old-app-version") {
        this.fromOldWebsite = true;
        setTimeout(() => {
          this.dialog.open(NewVersionDisclaimerComponent);
        }, 250);
      }
    });

    const isLoggedIn = this.auth.isLoggedIn();
    this.authStatusChanged(isLoggedIn);
    this.auth.authStatusChanged.subscribe((status) => {
      this.authStatusChanged(status.isLoggedIn);
    });
  }

  private authStatusChanged(isLoggedIn: boolean) {
    if (isLoggedIn) {
      const user = this.auth.getAuthInfo();
      if (user != null) {
        if (user.roles.indexOf("SportModerator") >= 0) {
          this.router.navigate(["/sport/moderator/schedule-printer"]);
        } else if (user.roles.indexOf("User") >= 0 || user.roles.indexOf("Moderator") >= 0) {
          this.router.navigate(["/apps"]);
        } else if (user.roles.indexOf("Admin") >= 0) {
          this.router.navigate(["/admin/dashboard"]);
        }
      }
    }
  }
}
