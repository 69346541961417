<h1 mat-dialog-title>Salut</h1>
<div mat-dialog-content>
  <p>Aceasta este noua versiune a aplicației de rezervări la spălătorii.</p>
  <p>
    În cazul în care observați nereguli vă rugăm să
    <a [routerLink]="['/contact']" (click)="close()">ne contactați</a>.
  </p>
</div>
<div mat-dialog-actions class="d-flex flex-row justify-content-end">
  <button mat-raised-button color="primary" (click)="close()">Ok</button>
</div>
