import { Component } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "@shared/services/auth.service";
import { MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { RegisterModel } from "@shared/models/register-model";
import { ErrorHandlerService } from "@shared/services/error-handler.service";
import { ConstantsService } from "@shared/services/constants.service";

@Component({
  selector: "booking-wash-register-student",
  templateUrl: "./register-student.component.html",
  styleUrls: ["./register-student.component.scss"],
})
export class RegisterStudentComponent {
  constructor(
    private readonly _formBuilder: FormBuilder,
    private readonly _authService: AuthService,
    private readonly _dialogRef: MatDialogRef<RegisterStudentComponent>,
    private readonly _snackBar: MatSnackBar,
    private readonly _errorHandler: ErrorHandlerService,
    private readonly _constants: ConstantsService
  ) {
    this.registerForm = this._formBuilder.group({
      emails: (this.emailFormGroup = this._formBuilder.group(
        {
          email: ["", [Validators.required, Validators.pattern(this._constants.emailRegex)]],
          repeatEmail: ["", Validators.required],
        },
        { validators: RegisterStudentComponent._areEqual }
      )),
      cnp: ["", [Validators.required]],
      acceptLicenseAgreement: [false, [Validators.required]],
      passwords: (this.passwordsFormGroup = this._formBuilder.group(
        {
          password: [
            "",
            [Validators.required, Validators.pattern(`.{${this.minPasswordLength},}`)],
          ],
          repeatPassword: ["", Validators.required],
        },
        { validators: RegisterStudentComponent._areEqual }
      )),
    });
  }

  registerForm: FormGroup;
  passwordsFormGroup: FormGroup;
  emailFormGroup: FormGroup;

  minPasswordLength = 6;
  status: string = null;

  private static _areEqual(group: FormGroup) {
    let valid = true;
    let firstValue: string = null;
    const error = {
      areEqual: true,
    };

    for (const name in group.controls) {
      if (!group.controls.hasOwnProperty(name)) {
        continue;
      }
      const control: AbstractControl = group.controls[name];
      const val = control.value;
      if (val !== null && firstValue === null) {
        firstValue = val;
      } else if (firstValue !== val) {
        valid = false;
        control.setErrors({ areEqual: true });
      }
    }

    return !valid ? error : null;
  }

  formSubmit(): void {
    if (!this.registerForm.valid) {
      return;
    }
    if (this.registerForm.controls["acceptLicenseAgreement"].value === false) {
      this._snackBar.open(
        "Trebuie să fi de acord cu termenii și condițiile înainte de a crea contul.",
        "Ok",
        {
          duration: 10000,
        }
      );
      return;
    }
    const model: RegisterModel = {
      email: this.emailFormGroup.value.email as string,
      password: this.passwordsFormGroup.value.password as string,
      cnp: this.registerForm.value.cnp as string,
    };
    this.status = "loading";
    this._authService.register(model).subscribe(
      () => {
        this.status = "success";
        this._snackBar.open(
          "Te-ai înregistrat cu succes! Vei primi un email de confirmare.",
          "Ok",
          {
            duration: 10000,
          }
        );
        this._dialogRef.close();
      },
      (error) => {
        this.status = "error";
        this._errorHandler.handleHttpClientError(error);
      }
    );
  }
}
