<h1 mat-dialog-title class="mat-title">Înregistrare</h1>

<form [formGroup]="registerForm" (submit)="formSubmit()">
  <div mat-dialog-content>
    <mat-form-field class="col-12">
      <input matInput placeholder="CNP" title="CNP" formControlName="cnp" />
      <mat-error *ngIf="registerForm.controls['cnp'].hasError('required')">
        Trebuie să completezi CNP-ul
      </mat-error>
    </mat-form-field>
    <div formGroupName="emails">
      <mat-form-field class="col-12">
        <input matInput placeholder="Email" title="Email" formControlName="email" type="email" />
        <mat-error *ngIf="emailFormGroup.controls['email'].hasError('required')">
          Trebuie să introduci o adresă de email.
        </mat-error>
        <mat-error *ngIf="emailFormGroup.controls['email'].hasError('pattern')">
          Trebuie să introduci o adresă de email validă.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-12">
        <input
          matInput
          placeholder="Confirmă email"
          title="Confirmă email"
          formControlName="repeatEmail"
          type="email"
          autocomplete="off"
        />
        <mat-error *ngIf="emailFormGroup.controls['repeatEmail'].hasError('required')">
          Adresele nu coincid
        </mat-error>
        <mat-error *ngIf="emailFormGroup.controls['repeatEmail'].hasError('areEqual')">
          Adresele nu coincid
        </mat-error>
      </mat-form-field>
    </div>
    <div formGroupName="passwords">
      <mat-form-field class="col-12">
        <input
          matInput
          placeholder="Parola"
          title="Parola"
          type="password"
          formControlName="password"
          autocomplete="new-password"
        />
        <mat-error *ngIf="passwordsFormGroup.controls['password'].hasError('required')">
          Trebuie să introduci o parolă
        </mat-error>
        <mat-error *ngIf="passwordsFormGroup.controls['password'].hasError('pattern')">
          Parola trebuie să aibă minim {{ minPasswordLength }} caractere.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-12">
        <input
          matInput
          placeholder="Confirmă parola"
          title="Confirmă parola"
          type="password"
          formControlName="repeatPassword"
          autocomplete="new-password"
        />
        <mat-error *ngIf="passwordsFormGroup.controls['repeatPassword'].hasError('required')">
          Parolele nu coincid
        </mat-error>
        <mat-error *ngIf="passwordsFormGroup.controls['repeatPassword'].hasError('areEqual')">
          Parolele nu coincid
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-12">
      <mat-checkbox
        class="mat-body-1"
        formControlName="acceptLicenseAgreement"
        matTooltip="Sunt de acord ca Universitatea Politehnica Timișoara să folosească datele mele cu carecter personal."
        position="below"
      >
        Sunt de acord cu termenii și condițiile.
      </mat-checkbox>
    </div>
  </div>
  <div mat-dialog-actions class="text-center">
    <div class="offset-2 col-8 margin-topBottom-16">
      <button mat-raised-button [color]="'primary'" [disabled]="status === 'loading'">
        ÎNREGISTREAZĂ-TE
        <i class="fa fa-spinner fa-pulse" *ngIf="status === 'loading'"></i>
      </button>
    </div>
  </div>
</form>
