import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ReservationModel } from "../models/reservation-model";
import { ConstantsService } from "./constants.service";
import { AuthService } from "./auth.service";
import { ErrorHandlerService } from "./error-handler.service";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class WashReservationService {
  items: BehaviorSubject<ReservationModel[]> = new BehaviorSubject<ReservationModel[]>([]);
  private _itemStore: ReservationModel[];

  constructor(
    private readonly _errorHandler: ErrorHandlerService,
    private readonly _http: HttpClient,
    private readonly _constants: ConstantsService,
    private readonly _auth: AuthService
  ) {}

  fetchAll(): void {
    this._itemStore = [];
    this.items.next(this._itemStore);
    const url = this._constants.apiUrl + "Reservation/GetAll";
    this._http.get(url, this._auth.getOptions2(true)).subscribe(
      (data: ReservationModel[]) => {
        this._itemStore = data;
        this.items.next(this._itemStore);
      },
      (error) => this._errorHandler.handleError(error)
    );
  }

  getAllByLaundry(laundryId: number): void {
    this._itemStore = [];
    this.items.next(this._itemStore);
    const url = this._constants.apiUrl + "Reservation/GetAllByLaundry/" + laundryId.toString();
    this._http.get(url, this._auth.getOptions2(true)).subscribe(
      (data: ReservationModel[]) => {
        this._itemStore = data;
        this.items.next(this._itemStore);
      },
      (error) => this._errorHandler.handleError(error)
    );
  }

  getHistory(): Observable<ReservationModel[]> {
    const url = this._constants.apiUrl + "Reservation/GetHistory";
    return this._http.get<ReservationModel[]>(url, this._auth.getOptions2(true));
  }

  add(item: ReservationModel) {
    const url = this._constants.apiUrl + "Reservation/Add";
    return this._http.post(url, item, this._auth.getOptions2(true));
  }

  update(item: ReservationModel) {
    const url = this._constants.apiUrl + "Reservation/Update";
    return this._http.put(url, item, this._auth.getOptions2(true));
  }

  delete(item: { id: number }) {
    const url = this._constants.apiUrl + "Reservation/Delete/" + item.id.toString();
    return this._http.delete(url, this._auth.getOptions2(true));
  }

  deleteInterval(item: any): Observable<string> {
    const url = this._constants.apiUrl + "Reservation/DeleteInterval";
    return this._http.post<string>(url, item, this._auth.getOptions2(true));
  }
}
