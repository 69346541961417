<div class="mat-elevation-z6 landing-container">
  <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
    <div class="padding-16" fxFlex.gt-sm="70" fxFlex.lt-md="100">
      <div class="mat-title"><strong>Booking</strong><span class="light">App</span></div>
      <div class="mat-body-2 padding-topBottom-8">
        Aplicația cu ajutorul căreia îți vei putea face rezervări la spălătoriile din cămine și la
        bazele sportive.
        <br />
        Astfel, toți studenții vor avea oportunitatea de a beneficia de acces la spălătoriile din
        cămine și terenurile din cadrul bazelor sportive în mod egal.
      </div>
      <br />
      <div>
        <div>
          <img class="logo img-responsive" src="/assets/images/cospol.png" />
        </div>
        <div>
          <img class="logo-small img-responsive" src="/assets/images/a4.png" />
          <img class="logo-small img-responsive" src="/assets/images/ligaac.png" />
          <img class="logo-small img-responsive" src="/assets/images/lsct.png" />
          <img class="logo-small img-responsive" src="/assets/images/lsfee.png" />
          <img class="logo-small img-responsive" src="/assets/images/lsfetc.png" />
          <img class="logo-small img-responsive" src="/assets/images/lsfmpt.png" />
          <img class="logo-small img-responsive" src="/assets/images/lsfmt.png" />
          <img class="logo-small img-responsive" src="/assets/images/ostl.png" />
        </div>
      </div>

      <div class="padding-topBottom-8">
        <small
          >Versiune: <b>{{ constants.version }}</b></small
        >
      </div>
    </div>
    <img
      fxFlex
      fxShow.lt-md="false"
      fxFlexAlign="center"
      class="poli-img"
      src="/assets/images/politehnica.jpg"
    />
  </div>
</div>
