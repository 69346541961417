import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { StudentModel } from "../models/student-model";
import { ConstantsService } from "./constants.service";
import { AuthService } from "./auth.service";
import { ErrorHandlerService } from "./error-handler.service";
import { StudentAdditionInfoModel } from "../models/student-additionalInfo-model";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable()
export class StudentService {
  items: BehaviorSubject<StudentModel[]> = new BehaviorSubject<StudentModel[]>([]);
  private _itemStore: StudentModel[];

  constructor(
    private readonly _errorHandler: ErrorHandlerService,
    private readonly _http: HttpClient,
    private readonly _constants: ConstantsService,
    private readonly _auth: AuthService
  ) {}

  fetchAll(): void {
    this._itemStore = [];
    this.items.next(this._itemStore);
    const url = this._constants.apiUrl + "Student/GetAll";
    this._http.get(url, this._auth.getOptions2(true)).subscribe(
      (data: StudentModel[]) => {
        this._itemStore = data;
        this.items.next(this._itemStore);
      },
      (error) => this._errorHandler.handleError(error)
    );
  }

  getAdditionalInfo(id: number): Observable<StudentAdditionInfoModel> {
    const url = this._constants.apiUrl + "Student/GetAdditionalInfo/" + id.toString();
    return <Observable<StudentAdditionInfoModel>>this._http.get(url, this._auth.getOptions2(true));
  }

  add(item: StudentModel) {
    const url = this._constants.apiUrl + "Student/Add";
    return this._http.post(url, item, this._auth.getOptions2(true));
  }

  update(item: StudentModel) {
    const url = this._constants.apiUrl + "Student/Update";
    return this._http.put(url, item, this._auth.getOptions2(true));
  }

  delete(item: StudentModel) {
    const url = this._constants.apiUrl + "Student/Delete/" + item.id.toString();
    return this._http.delete(url, this._auth.getOptions2(true));
  }

  deleteUserAccountForStudent(item: StudentModel) {
    const url =
      this._constants.apiUrl + "Student/DeleteUserAccountForStudent/" + item.id.toString();
    return this._http.delete(url, this._auth.getOptions2(true));
  }

  getStudentInfo(): Observable<StudentModel> {
    const url = this._constants.apiUrl + "Student/GetStudentInfo/";
    return <Observable<StudentModel>>this._http.get(url, this._auth.getOptions2(true));
  }

  getConvertedStudent(cnp: string): Observable<StudentModel> {
    const url = `${this._constants.apiUrl}Student/ConvertRawStudent?cnp=${cnp}`;

    return this._http.get(url, this._auth.getOptions2(true)).pipe(
      map((result) => {
        return <StudentModel>result;
      })
    );
  }

  giveModerator(id: number): Observable<any> {
    const url = this._constants.apiUrl + `Student/GiveModeratorRole/${id}`;
    return <Observable<any>>this._http.patch(url, {}, this._auth.getOptions2(true));
  }

  removeModerator(id: number): Observable<any> {
    const url = this._constants.apiUrl + `Student/RemoveModeratorRole/${id}`;
    return <Observable<any>>this._http.patch(url, {}, this._auth.getOptions2(true));
  }
}
