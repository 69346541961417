<div class="container">
  <mat-progress-spinner
    [hidden]="somethingReady"
    diameter="30"
    color="primary"
    class="small-spinner"
    mode="indeterminate"
  >
  </mat-progress-spinner>
  <div
    *ngIf="washReservations === null && sportReservations === null && loadingWash === false"
    class="col-md-12 text-gray text-center"
  >
    <div class="mat-body-2">
      Istoricul rezervărilor nu este disponibil. Încearcă să dai un refresh acestei pagini.
    </div>
    <div class="mat-body-2">
      Dacă problema persistă te rugăm să ne
      <a [routerLink]="['/', 'contact']">contactezi</a>.
    </div>
  </div>
  <div *ngIf="somethingReady">
    <div class="row margin-bottom-4">
      <div class="mat-title col-md-6">Istoric</div>
    </div>
    <mat-tab-group class="margin-top-8" [selectedIndex]="selectedTabIndex">
      <mat-tab label="Spălătorii" *ngIf="hasDormRole">
        <div class="padding-8">
          <div
            *ngIf="washReservations && washReservations.length === 0"
            class="col-md-12 text-gray text-center"
          >
            <div class="mat-body-2">Nu există rezervări realizate pană acum.</div>
          </div>
          <div *ngIf="washReservations && washReservations.length >= 1">
            <div
              class="mat-elevation-z4 padding-16 margin-bottom-8 reservation-card"
              *ngFor="let reservation of washReservations"
            >
              <div class="row">
                <div class="col-md-2">{{ reservation.startDateTimeText }}</div>
                <div class="col-md-4">
                  <small>camera</small>
                  <b>{{ reservation.room.name }}</b>
                </div>
                <div class="col-md-4">
                  <b>{{ reservation.student.name }}</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Baze sportive">
        <div class="padding-8">
          <div
            *ngIf="sportReservations && sportReservations.length === 0"
            class="col-md-12 text-gray text-center"
          >
            <div class="mat-body-2">Nu există rezervări realizate pană acum.</div>
          </div>
          <div *ngIf="sportReservations && sportReservations.length >= 1">
            <div
              class="mat-elevation-z4 padding-16 margin-bottom-8 reservation-card"
              *ngFor="let reservation of sportReservations"
            >
              <div class="row">
                <div class="col-md-2">
                  {{ formatDate(reservation.startDateTime) }}
                </div>
                <div class="col-md-4">
                  <b>{{ reservation.sportSpace.name }}</b>
                </div>
                <div class="col-md-4">
                  <b>{{ reservation.student.name }}</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
