import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { SportReservationModel } from "../../models/sport/sport-reservation.model";
import { ConstantsService } from "../constants.service";
import { BehaviorSubject, Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { AuthService } from "../auth.service";
import { ErrorHandlerService } from "../error-handler.service";
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Injectable()
export class SportReservationService {
  items: BehaviorSubject<SportReservationModel[]> = new BehaviorSubject<SportReservationModel[]>(
    []
  );
  private itemStore: SportReservationModel[];

  constructor(
    private http: HttpClient,
    private constants: ConstantsService,
    private auth: AuthService,
    private errorHandler: ErrorHandlerService,
    private recaptchaService: ReCaptchaV3Service
  ) {}

  fetchAll() {
    this.itemStore = [];
    this.items.next(this.itemStore);
    const url = this.constants.apiUrl + "SportReservation/GetAll";
    this.http.get(url, this.auth.getOptions2(true)).subscribe(
      (data: SportReservationModel[]) => {
        this.itemStore = data;
        this.items.next(this.itemStore);
      },
      (error) => this.errorHandler.handleError(error)
    );
  }

  getAllBySportSpace(sportSpaceId: number) {
    this.itemStore = [];
    this.items.next(this.itemStore);
    const url = this.constants.apiUrl + "SportReservation/GetAllBySportSpace/" + sportSpaceId;
    this.http.get(url, this.auth.getOptions2(true)).subscribe(
      (data: SportReservationModel[]) => {
        this.itemStore = data;
        this.items.next(this.itemStore);
      },
      (error) => this.errorHandler.handleError(error)
    );
  }

  getHistory(): Observable<SportReservationModel[]> {
    const url = this.constants.apiUrl + "SportReservation/GetHistory";
    return <Observable<SportReservationModel[]>>this.http.get(url, this.auth.getOptions2(true));
  }

  add(reservation: SportReservationModel): Observable<SportReservationModel> {
    return this.recaptchaService.execute("importantAction").pipe(
      switchMap((token) => {
        const url = this.constants.apiUrl + `SportReservation/Add?token=${token}`;
        return this.http.post(url, reservation, this.auth.getOptions2(true)).pipe(
          map((response) => {
            return <SportReservationModel>response;
          })
        );
      })
    );
  }

  update(reservation: SportReservationModel): Observable<SportReservationModel> {
    const url = this.constants.apiUrl + "SportReservation/Update";
    return this.http.put(url, reservation, this.auth.getOptions2(true)).pipe(
      map((response) => {
        return <SportReservationModel>response;
      })
    );
  }

  delete(reservation: { id: number }): Observable<any> {
    const url = this.constants.apiUrl + "SportReservation/Delete/" + reservation.id;
    return this.http.delete(url, this.auth.getOptions2(true));
  }

  deleteInterval(item: any): Observable<string> {
    const url = this.constants.apiUrl + "SportReservation/DeleteInterval";
    return <Observable<string>>this.http.post(url, item, this.auth.getOptions2(true));
  }
}
