import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ConstantsService } from "../constants.service";
import { AuthService } from "../auth.service";
import { BehaviorSubject, Observable } from "rxjs";
import { GetRequestCachingHttpClient } from "../caching/get-request-caching-http-client";
import { ErrorHandlerService } from "../error-handler.service";
import { SportFacultyLimitationsModel } from "../../models/sport/sport-faculty-limitations.model";
import { map } from "rxjs/operators";

@Injectable()
export class SportFacultyLimitationsService {
  items: BehaviorSubject<SportFacultyLimitationsModel[]> = new BehaviorSubject<
    SportFacultyLimitationsModel[]
  >([]);
  private _itemStore: SportFacultyLimitationsModel[];

  constructor(
    private readonly _grchc: GetRequestCachingHttpClient,
    private readonly _constants: ConstantsService,
    private readonly _errorHandler: ErrorHandlerService,
    private readonly _http: HttpClient,
    private readonly _auth: AuthService
  ) {}

  fetchAll(): void {
    this._itemStore = [];
    this.items.next(this._itemStore);
    const url = this._constants.apiUrl + "sportspacefacultymaxreservation/getall";
    this._grchc
      .get(url, this._auth.getOptions2(true))
      .pipe(
        map((response: SportFacultyLimitationsModel[]) => {
          return response;
        })
      )
      .subscribe(
        (data) => {
          this._itemStore = data;
          this.items.next(this._itemStore);
        },
        (error) => this._errorHandler.handleError(error)
      );
  }

  getAllWithSubscribe(): Observable<SportFacultyLimitationsModel[]> {
    const url = this._constants.apiUrl + "sportspacefacultymaxreservation/getall";

    return this._grchc.get(url, this._auth.getOptions2(true)).pipe(
      map((response: SportFacultyLimitationsModel[]) => {
        return response;
      })
    );
  }

  add(item: SportFacultyLimitationsModel): Observable<SportFacultyLimitationsModel> {
    const url = this._constants.apiUrl + "sportspacefacultymaxreservation/add";
    return this._http
      .post(url, item, this._auth.getOptions2(true))
      .pipe(map((response) => <SportFacultyLimitationsModel>response));
  }

  update(item: SportFacultyLimitationsModel): Observable<SportFacultyLimitationsModel> {
    const url = this._constants.apiUrl + "sportspacefacultymaxreservation/update";
    return this._http
      .put(url, item, this._auth.getOptions2(true))
      .pipe(map((response) => <SportFacultyLimitationsModel>response));
  }

  delete(item: SportFacultyLimitationsModel) {
    const url =
      this._constants.apiUrl + "sportspacefacultymaxreservation/delete/" + item.id.toString();
    return this._http.delete(url, this._auth.getOptions2(true));
  }
}
