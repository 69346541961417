<h1 mat-dialog-title class="mat-title">Resetare parolă</h1>
<form [formGroup]="loginForm" (submit)="formSubmit()">
  <div mat-dialog-content>
    <mat-form-field class="col-12">
      <input matInput placeholder="Adresă email" title="Adresă email" formControlName="email" />
      <mat-error *ngIf="loginForm.controls['email'].hasError('required')">
        Trebuie să introduci o adresă de email.
      </mat-error>
      <mat-error *ngIf="loginForm.controls['email'].hasError('pattern')">
        Trebuie să introduci o adresă de email validă.
      </mat-error>
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="text-center">
    <div class="offset-2 col-8 margin-topBottom-16">
      <button mat-raised-button [color]="'primary'" [disabled]="status === 'loading'">
        TRIMITE EMAIL
        <i class="fa fa-spinner fa-pulse" *ngIf="status === 'loading'"></i>
      </button>
    </div>
  </div>
</form>
