import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: "booking-app-selector-page",
  templateUrl: "./app-selector-page.component.html",
  styleUrls: ["./app-selector-page.component.scss"],
})
export class AppSelectorPageComponent implements OnInit {
  isAdmin = false;

  constructor(private auth: AuthService, private router: Router) {}

  ngOnInit() {
    const user = this.auth.getAuthInfo();
    if (user.roles.indexOf("Admin") >= 0) {
      this.isAdmin = true;
      this.router.navigate(["admin"]);
    } else if (!this.auth.hasDormRole) {
      this.router.navigate(["sport"]);
    }
  }
}
