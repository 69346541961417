<div class="mat-elevation-z6 padding-16 contact-container">
  <div class="col-12 col-md-12 margin-bottom-8 mat-title">
    <b>Suport tehnic</b>
  </div>

  <div class="col-12 col-md-8 padding-topBottom-8">
    <ul class="no-list">
      <li class="margin-topBottom-4" *ngFor="let contactPerson of contactPersons">
        <b>
          {{ contactPerson.name }}
        </b>
      </li>
    </ul>
  </div>
  <p class="col-12 col-md-12 mat-body-2">
    În cazul în care întâlnești dificultăți noi îți stăm la dispoziție. <br />
    Tot ce trebuie să faci este să completezi următorul formular în care să descrii problema.<br />
    <br />
  </p>

  <booking-ticket-form></booking-ticket-form>

  <p class="col-12 col-md-12 mat-body-2 pt-4">
    Dacă observi probleme de funcționalitate sau ai sugestii de îmbunătățire a platformei ne poți
    contacta completând acelaşi formular.
  </p>

  <div class="col-md-12 mat-body-2 padding-topBottom-8">
    <small
      >Versiune: <strong>{{ constants.version }}</strong></small
    >
  </div>
</div>
