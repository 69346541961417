import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Observable, Observer } from "rxjs";
import { LoginComponent } from "../components/auth/login/login.component";

@Injectable()
export class LoginService {
  constructor(private dialog: MatDialog) {}

  loginDialog(): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      const dialogRef: MatDialogRef<LoginComponent> = this.dialog.open(LoginComponent, {
        width: "400px",
      });
      const subscription = dialogRef.afterClosed().subscribe((closedResult) => {
        observer.next(closedResult);
        subscription.unsubscribe();
      });
    });
  }
}
