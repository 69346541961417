import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "@shared/services/auth.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ErrorHandlerService } from "@shared/services/error-handler.service";
import { ResetPasswordModel } from "@shared/models/reset-password.model";

@Component({
  selector: "booking-wash-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent {
  constructor(
    private readonly _formBuilder: FormBuilder,
    private readonly _authService: AuthService,
    private readonly _dialogRef: MatDialogRef<ChangePasswordComponent>,
    private readonly _snackBar: MatSnackBar,
    private readonly _errorHandlerService: ErrorHandlerService,
    @Inject(MAT_DIALOG_DATA)
    private readonly _data: { userId: string; code: string }
  ) {
    this.registerForm = this._formBuilder.group({
      passwords: (this.passwordsFormGroup = this._formBuilder.group(
        {
          password: [
            "",
            [Validators.required, Validators.pattern(`.{${this.minPasswordLength},}`)],
          ],
          repeatPassword: ["", Validators.required],
        },
        { validators: ChangePasswordComponent._areEqual }
      )),
    });
  }

  registerForm: FormGroup;
  passwordsFormGroup: FormGroup;

  minPasswordLength = 6;
  status: string = null;

  private static _areEqual(group: FormGroup) {
    let valid = true;
    let firstValue: string;

    for (const name in group.controls) {
      if (!group.controls.hasOwnProperty(name)) {
        continue;
      }
      const val = group.controls[name].value;
      if (typeof firstValue === "undefined") {
        firstValue = val;
      }
      if (firstValue !== val) {
        valid = false;
        break;
      }
    }
    if (valid) {
      return null;
    }
    const repeatPasswordControl = group.controls["repeatPassword"];
    repeatPasswordControl.setErrors({ areEqual: true });

    return {
      areEqual: true,
    };
  }

  formSubmit(): void {
    if (!this.registerForm.valid) {
      return;
    }
    this.status = "loading";
    const model: ResetPasswordModel = {
      userId: this._data.userId,
      code: this._data.code,
      password: this.registerForm.controls["passwords"].value.password as string,
      confirmPassword: this.registerForm.controls["passwords"].value.repeatPassword as string,
    };
    this._authService.changePassword(model).subscribe(
      (response) => {
        this.status = "success";
        this._snackBar.open(response, "Ok", {
          duration: 10000,
        });
        this._dialogRef.close(true);
      },
      (error) => {
        this.status = "error";
        this._errorHandlerService.handleHttpClientError(error);
      }
    );
  }
}
