import { Injectable } from "@angular/core";
import { LaundryModel } from "../models/laundry-model";
import { BehaviorSubject, Observable } from "rxjs";
import { ConstantsService } from "./constants.service";
import { AuthService } from "./auth.service";
import { ErrorHandlerService } from "./error-handler.service";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class LaundryService {
  items: BehaviorSubject<LaundryModel[]> = new BehaviorSubject<LaundryModel[]>([]);
  private itemStore: LaundryModel[];

  constructor(
    private errorHandler: ErrorHandlerService,
    private http: HttpClient,
    private constants: ConstantsService,
    private auth: AuthService
  ) {}

  fetchAll() {
    this.itemStore = [];
    this.items.next(this.itemStore);
    const url = this.constants.apiUrl + "Laundry/GetAll";
    this.http.get(url, this.auth.getOptions2(true)).subscribe(
      (data: LaundryModel[]) => {
        this.itemStore = data;
        this.items.next(this.itemStore);
      },
      (error) => this.errorHandler.handleError(error)
    );
  }

  getAllByDorm(dormId: number): Observable<LaundryModel[]> {
    const url = this.constants.apiUrl + "Laundry/GetLaundriesByDorm/" + dormId;
    return <Observable<LaundryModel[]>>this.http.get(url, this.auth.getOptions2(true));
  }

  add(item: LaundryModel) {
    const url = this.constants.apiUrl + "Laundry/Add";
    return this.http.post(url, item, this.auth.getOptions2(true));
  }

  update(item: LaundryModel) {
    const url = this.constants.apiUrl + "Laundry/Update";
    return this.http.put(url, item, this.auth.getOptions2(true));
  }

  delete(item: LaundryModel) {
    const url = this.constants.apiUrl + "Laundry/Delete/" + item.id;
    return this.http.delete(url, this.auth.getOptions2(true));
  }
}
