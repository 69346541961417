import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { SportSpaceModel } from "../../models/sport/sport-space.model";
import { ConstantsService } from "../constants.service";
import { map } from "rxjs/operators";
import { GetRequestCachingHttpClient } from "../caching/get-request-caching-http-client";
import { AuthService } from "../auth.service";
import { ErrorHandlerService } from "../error-handler.service";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class SportSpaceService {
  items: BehaviorSubject<SportSpaceModel[]> = new BehaviorSubject<SportSpaceModel[]>([]);
  private itemStore: SportSpaceModel[];

  constructor(
    private grchc: GetRequestCachingHttpClient,
    private constants: ConstantsService,
    private errorHandler: ErrorHandlerService,
    private http: HttpClient,
    private auth: AuthService
  ) {}

  fetchAll() {
    this.itemStore = [];
    this.items.next(this.itemStore);
    const url = this.constants.apiUrl + "sportspace/getall";
    this.grchc
      .get(url, this.auth.getOptions2(true))
      .pipe(
        map((response: SportSpaceModel[]) => {
          return response;
        })
      )
      .subscribe(
        (data) => {
          this.itemStore = data;
          this.items.next(this.itemStore);
        },
        (error) => this.errorHandler.handleError(error)
      );
  }

  getAllWithSubscribe(): Observable<SportSpaceModel[]> {
    const url = this.constants.apiUrl + "sportspace/getall";

    return this.grchc.get(url, this.auth.getOptions2(true)).pipe(
      map((response: SportSpaceModel[]) => {
        return response;
      })
    );
  }

  add(item: SportSpaceModel): Observable<SportSpaceModel> {
    const url = this.constants.apiUrl + "sportspace/add";
    return this.http
      .post(url, item, this.auth.getOptions2(true))
      .pipe(map((response) => <SportSpaceModel>response));
  }

  update(item: SportSpaceModel): Observable<SportSpaceModel> {
    const url = this.constants.apiUrl + "sportspace/update";
    return this.http
      .put(url, item, this.auth.getOptions2(true))
      .pipe(map((response) => <SportSpaceModel>response));
  }

  delete(item: SportSpaceModel) {
    const url = this.constants.apiUrl + "sportspace/delete/" + item.id;
    return this.http.delete(url, this.auth.getOptions2(true));
  }

  clearCache() {
    this.grchc.clearCache();
  }
}
