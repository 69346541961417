import { Injectable } from "@angular/core";
import { ConstantsService } from "./constants.service";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "./auth.service";
import { TicketModel } from "../models/ticket-model";

@Injectable({
  providedIn: "root",
})
export class TicketsService {
  constructor(
    private readonly _http: HttpClient,
    private readonly _constants: ConstantsService,
    private readonly _auth: AuthService
  ) {}

  createTicket(model: any): Observable<any> {
    const url = this._constants.apiUrl + "Tickets/Add";
    return this._http.post(url, model);
  }

  getAllTickets(): Observable<TicketModel[]> {
    const url = this._constants.apiUrl + "Tickets/GetAll";
    return <Observable<TicketModel[]>>this._http.get(url, this._auth.getOptions2(true));
  }

  autoResolve(id: number): Observable<any> {
    const url = this._constants.apiUrl + `Tickets/AutoResolve`;
    return this._http.patch(url, id, this._auth.getOptions2(true));
  }

  changeResolvedState(id: number): Observable<any> {
    const url = this._constants.apiUrl + `Tickets/ChangeResolvedState`;
    return this._http.patch(url, id, this._auth.getOptions2(true));
  }

  changeResponsible(id: any): Observable<any> {
    const url = this._constants.apiUrl + `Tickets/ChangeResponsible`;
    return this._http.patch(url, id, this._auth.getOptions2(true));
  }

  sendProblemResolvedMail(ticketId): Observable<any> {
    const url = this._constants.apiUrl + "Tickets/SendProblemResolvedMail";
    return this._http.post(url, ticketId, this._auth.getOptions2(true));
  }
}
