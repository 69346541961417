import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LandingPageComponent } from "@shared/pages/landing-page/landing-page.component";
import { ContactComponent } from "@shared/pages/contact/contact.component";
import { AppSelectorPageComponent } from "@shared/pages/app-selector-page/app-selector-page.component";
import { SharedModule } from "@shared/shared.module";
import { ProfilePageComponent } from "@shared/pages/profile-page/profile-page.component";
import { HistoryPageComponent } from "@shared/pages/history-page/history-page.component";
import { AuthGuard } from "@shared/guards/auth.guard";

const routes: Routes = [
  {
    path: "admin",
    loadChildren: () => import("./admin/admin.module").then((m) => m.AdminModule),
  },
  {
    path: "wash",
    children: [
      {
        path: "moderator",
        loadChildren: () =>
          import("./wash/wash-moderator/wash-moderator.module").then((m) => m.WashModeratorModule),
      },
      {
        path: "",
        loadChildren: () => import("./wash/wash/wash.module").then((m) => m.WashModule),
      },
    ],
  },
  {
    path: "sport",
    children: [
      {
        path: "moderator",
        loadChildren: () =>
          import("./sport/sport-moderator/sport-moderator.module").then(
            (m) => m.SportModeratorModule
          ),
      },
      {
        path: "",
        loadChildren: () => import("./sport/sport/sport.module").then((m) => m.SportModule),
      },
    ],
  },
  {
    path: "landing-page/:isConfirmed",
    component: LandingPageComponent,
  },
  {
    path: "landing-page/:userId/:code",
    component: LandingPageComponent,
  },
  {
    path: "landing-page/",
    component: LandingPageComponent,
  },
  {
    path: "apps",
    component: AppSelectorPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "history",
    component: HistoryPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "contact",
    component: ContactComponent,
  },
  {
    path: "profile",
    component: ProfilePageComponent,
  },
  {
    path: "",
    redirectTo: "landing-page/",
    pathMatch: "full",
  },
  {
    path: "**",
    redirectTo: "landing-page/",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" }), SharedModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
