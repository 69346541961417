<div class="row margin-bottom-8">
  <div class="table-title col-md-6">
    <!-- <div class="mat-title">Bun venit la <strong>Booking</strong><span class="light">App</span></div> -->
    <div class="mat-body-1">Alege categoria la care vrei să faci rezervări</div>
  </div>
</div>
<div
  class="apps-container"
  fxLayout.gt-xs="row"
  fxLayout.lt-sm="column"
  fxLayoutAlign="start stretch"
  fxLayoutGap="1em"
>
  <a
    class="mat-elevation-z5 card padding-16"
    fxFlex.gt-xs="25"
    fxFlex.lt-sm="100"
    [routerLink]="['/' + (isAdmin ? 'admin' : ''), 'sport']"
  >
    <img class="img-responsive" src="/assets/icons/fotbal.svg" />
    <div class="mat-body-2 text-center">Baze sportive</div>
  </a>
  <a
    class="mat-elevation-z5 card padding-16"
    fxFlex.gt-xs="25"
    fxFlex.lt-sm="100"
    [routerLink]="['/' + (isAdmin ? 'admin' : ''), 'wash']"
  >
    <img class="img-responsive" src="/assets/icons/wash.svg" />
    <div class="mat-body-2 text-center">Spălătorii</div>
  </a>
</div>
