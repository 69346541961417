import { Injectable } from "@angular/core";
import { ConstantsService } from "./constants.service";
import { Observable } from "rxjs";
import { FacultyModel } from "../models/faculty-model";
import { AuthService } from "./auth.service";
import { HttpClient } from "@angular/common/http";
import { GetRequestCachingHttpClient } from "./caching/get-request-caching-http-client";

@Injectable()
export class FacultyService {
  constructor(
    private http: HttpClient,
    private constants: ConstantsService,
    private auth: AuthService,
    private grchc: GetRequestCachingHttpClient
  ) {}

  getAllWithSubscribe(): Observable<FacultyModel[]> {
    const url = this.constants.apiUrl + "Faculty/GetAll";
    return <Observable<FacultyModel[]>>this.http.get(url, this.auth.getOptions2(true));
  }

  getAll(): Observable<FacultyModel[]> {
    const url = this.constants.apiUrl + "Faculty/GetAll";
    return this.grchc.get(url, this.auth.getOptions2(true));
  }
}
