<div class="side-menu mat-elevation-z6 hidden" [ngClass]="{ hidden: hidden }">
  <table>
    <td>
      <tr class="menu-item background-lightGray" *ngIf="isLoggedIn">
        <a class="mat-subheading-1 margin-bottom-0 padding-bottom-0"
          ><b>{{ authName }}</b></a
        >
        <a (click)="menuItemClicked(); goToProfilePage()" class="pointer padding-topBottom-0">
          <span class="mat-caption padding-top-0">Profil</span>
        </a>
        <a
          (click)="menuItemClicked(); doLogout()"
          class="pointer padding-topBottom-0 margin-bottom-4"
        >
          <span class="mat-caption padding-top-0">Deconectează-te</span>
        </a>
      </tr>
      <tr class="menu-item" *ngFor="let menuItem of menuItems">
        <a *ngIf="menuItem.route" [routerLink]="[menuItem.route]" (click)="menuItemClicked()">
          <span class="mat-body-2">{{ menuItem.name }}</span>
        </a>
      </tr>
      <tr class="menu-item" *ngIf="!isLoggedIn">
        <a (click)="menuItemClicked(); openLoginModal()" *ngIf="!isLoggedIn">
          <span class="mat-body-2 padding-left-0">Conectează-te</span>
        </a>
      </tr>
    </td>
  </table>
</div>
