import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class GoogleAnalyticsService {
  constructor() {}

  private tries = 0;

  event(category: string, action: string, label: string): void {
    const win: any = window;
    // console.log(`ga event: ${category} ${action} ${label}`);

    if ("ga" in win) {
      const tracker = win.ga.getAll()[0];
      if (tracker) {
        tracker.send("event", category, action, label);
      }
    }
  }

  sendViewPageEvent(url: string) {
    if ("ga" in <any>window) {
      (<any>window).ga("set", "page", url);
      (<any>window).ga("send", "pageview");
    } else {
      // wait for ga script load
      this.tries++;
      if (this.tries > 10) {
        return;
      }
      setTimeout(() => {
        this.sendViewPageEvent(url);
      }, 1000);
    }
  }
}
